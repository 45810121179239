import React, { useEffect } from 'react'
import HomeHeader from './HomeHeader'
import { AgentImageContainer, AgentName, AgentOfMonthCard, BannerContainer, HomeStyles } from './Home.styles'
import { useTranslation } from 'react-i18next'
import Button from '../../components/common/Button'
import tiranaImg from '../../assets/images/tirana.jpg'
import durresImg from '../../assets/images/durres.jpg'
import vloreImg from '../../assets/images/vlore.jpg'
import sarandaImg from '../../assets/images/saranda.jpg'
import shengjinImg from '../../assets/images/shengjin.jpg'
import CitiesHome from '../../components/Home/CitiesHome'
import HomeSliderComponent from '../../components/Home/HomeSliderComponent'
import agentBadge from '../../assets/images/agent_of_month_badge.png'
import AgentsSlider from '../../components/Home/AgentsSlider'
import { useSelector } from 'react-redux'
import { fetchHomeData } from '../../feature/home/homeActions'
import PageLoader from '../../components/common/PageLoader'
import HomeSliderComponentFeatured from '../../components/Home/HomeSliderComponentFeatured'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

function Home() {
  const { loading, featured_properties, latest_properties, agents, areas } = useSelector(store => store.home.home)
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    fetchHomeData()
  }, [localStorage.getItem('language')])

  if (loading) {
    return <PageLoader />
  }

  return (
    <>
      {localStorage.getItem("language") === 'sq' ? <Helmet>
        <title data-n-head="true">ProALB Realestate Group | Qira, Shitje | Apartamente, Vila, Biznes</title>
        <meta name="description" content="ProALB RealEstate Group është angazhuar të jetë një vlerë e shtuar në sektorin e pasurive të paluajtshme duke ofruar eksperienca të shkëlqyera për të gjithë bashkëpunëtorët tanë." />
        <meta name="apple-mobile-web-app-title" content="ProALB Realestate Group | Qira, Shitje | Apartamente, Vila, Biznes" />
        <meta name="keywords" content="proalb, realestate, albania, tirana, prona, ne shitje, qera, apartamente, imobiliare, vila, studjo, biznese, trruall, komerciale, shtepi, shpi ne shitje, shpi me qera, rezidenca banimi, komplekse, ndertesa" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="twitter:card" content="https://proalbrealestate.com/images/icon.png" />
        <meta name="twitter:description" content=" ProALB RealEstate Group është angazhuar të jetë një vlerë e shtuar në sektorin e pasurive të paluajtshme duke ofruar eksperienca të shkëlqyera për të gjithë bashkëpunëtorët tanë." />
        <meta name="twitter:title" content="ProALB Realestate Group | Qira, Shitje | Apartamente, Vila, Biznes" />
        <meta name="twitter:image" content="https://proalbrealestate.com/images/icon.png" />
        <meta property="ia:markup_url" content="https://proalbrealestate.com/?ia_markup=1" />
        <meta property="og:locale" content="sq_AL" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="ProALB Realestate Group | Qira, Shitje | Apartamente, Vila, Biznes" />
        <meta property="og:description" content=" ProALB RealEstate Group është angazhuar të jetë një vlerë e shtuar në sektorin e pasurive të paluajtshme duke ofruar eksperienca të shkëlqyera për të gjithë bashkëpunëtorët tanë." />
        <meta property="og:image" content="https://proalbrealestate.com/images/icon.png" />
        <meta property="og:url" content="https://proalbrealestate.com/" />
        <meta property="og:image:secure_url" content="https://proalbrealestate.com/images/icon.png?" />
        <meta property="og:image:type" content="image/png images.jpg" />
        <meta property="og:image:alt" content="ProALB Realestate Group | Qira, Shitje | Apartamente, Vila, Biznes" />
      </Helmet> : <Helmet>
        <title data-n-head="true">ProALB Realestate Group | Rent, Sale | Apartments, Villas, Business</title>
        <meta name="description" content=" ProALB Real Estate Group is committed to being an added value in the real estate sector by providing excellent experiences to all our associates." />
        <meta name="apple-mobile-web-app-title" content="ProALB Realestate Group | Rent, Sale | Apartments, Villas, Business" />
        <meta name="keywords" content="proalb, realestate, albania, tirana, property, for sale, rent, apartments, real estate, villas, studios, businesses, plots, commercial, houses, houses for sale, houses for rent, residential residences, complexes, buildings" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="twitter:card" content="https://proalbrealestate.com/images/icon.png" />
        <meta name="twitter:description" content="ProALB Real Estate Group is committed to being an added value in the real estate sector by providing excellent experiences to all our associates." />
        <meta name="twitter:title" content="ProALB Realestate Group | Rent, Sale | Apartments, Villas, Business" />
        <meta name="twitter:image" content="https://proalbrealestate.com/images/icon.png" />
        <meta property="ia:markup_url" content="https://proalbrealestate.com/?ia_markup=1" />
        <meta property="og:locale" content="sq_en" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="ProALB Realestate Group | Rent, Sale | Apartments, Villas, Business" />
        <meta property="og:description" content="ProALB Real Estate Group is committed to being an added value in the real estate sector by providing excellent experiences to all our associates." />
        <meta property="og:image" content="https://proalbrealestate.com/images/icon.png" />
        <meta property="og:url" content="https://proalbrealestate.com/en" />
        <meta property="og:image:secure_url" content="https://proalbrealestate.com/images/icon.png? " />
        <meta property="og:image:type" content="image/png images.jpg" />
        <meta property="og:image:alt" content="ProALB Realestate Group | Rent, Sale | Apartments, Villas, Business" />
      </Helmet>}
      <HomeStyles className="container-fluid d-flex align-items-center justify-content-center">
        <HomeHeader areas={areas} />
      </HomeStyles>
      <div className='py-0 py-md-1' />
      <div className="container-fluid my-5">
        <h2 className='h4 text-center text-primary fw-bold mb-4'>{t('home.featured_properties')}</h2>
        {featured_properties.length > 0 ? <HomeSliderComponentFeatured paginationClass={'swiper-custom-pagination'} properties={featured_properties} /> : <div className='alert alert-info'>
          {localStorage.getItem("language") === 'sq' ? 'Nuk ka prona te perzgjedhura' : 'No Featured Properties'}
        </div>}
        <Button buttonClass={'btn-primary d-table mx-auto mt-4 mt-md-5'} buttonText={t('home.all_properties')} onClick={() => navigate('/properties')} />
      </div>
      <div className='py-0 py-md-1' />
      <div className="container-xl my-5">
        <h2 className='h4 text-center text-primary fw-bold mb-4'>{t('home.latest_properties')}</h2>
        <HomeSliderComponent paginationClass={'swiper-custom-pagination-latest'} properties={latest_properties} />
        <Button buttonClass={'btn-primary d-table mx-auto mt-4 mt-md-5'} buttonText={t('home.all_properties')} onClick={() => navigate('/properties')} />
      </div>
      <div className='py-0 py-md-1' />
      <div className="container-xl my-5">
        <div className="row">
          <div className="col-12 col-md-7 col-lg-8 p-1 rounded-2" onClick={() => navigate('/properties', {
            state: {
              params: {
                business_type: '',
                property_type: '',
                city_id: 1,
                area_id: '',
                bedrooms: '',
                bathrooms: '',
                price_from: '',
                price_to: '',
                area_min: '',
                area_max: '',
                search: ''
              }
            }
          })}>
            <CitiesHome imgSrc={tiranaImg} cityAlt={'Tirana'} cityName={'Tirana'} />
          </div>
          <div className="col-12 col-md-5 col-lg-4 p-1 rounded-2" onClick={() => navigate('/properties', {
            state: {
              params: {
                business_type: '',
                property_type: '',
                city_id: 23,
                area_id: '',
                bedrooms: '',
                bathrooms: '',
                price_from: '',
                price_to: '',
                area_min: '',
                area_max: '',
                search: ''
              }
            }
          })} >
            <CitiesHome imgSrc={durresImg} cityAlt={'Durres'} cityName={'Durres'} />
          </div>
          <div className="col-12 col-md-4 col-lg-3 p-1 rounded-2" onClick={() => navigate('/properties', {
            state: {
              params: {
                business_type: '',
                property_type: '',
                city_id: 8,
                area_id: '',
                bedrooms: '',
                bathrooms: '',
                price_from: '',
                price_to: '',
                area_min: '',
                area_max: '',
                search: ''
              }
            }
          })}>
            <CitiesHome imgSrc={vloreImg} cityAlt={'Vlore'} cityName={'Vlore'} />
          </div>
          <div className="col-12 col-md-4 col-lg-6 p-1 rounded-2" onClick={() => navigate('/properties', {
            state: {
              params: {
                business_type: '',
                property_type: '',
                city_id: 9,
                area_id: '',
                bedrooms: '',
                bathrooms: '',
                price_from: '',
                price_to: '',
                area_min: '',
                area_max: '',
                search: ''
              }
            }
          })}>
            <CitiesHome imgSrc={sarandaImg} cityAlt={'Sarande'} cityName={'Sarande'} />
          </div>
          <div className="col-12 col-md-4 col-lg-3 p-1 rounded-2" onClick={() => navigate('/properties', {
            state: {
              params: {
                business_type: '',
                property_type: '',
                city_id: 85,
                area_id: '',
                bedrooms: '',
                bathrooms: '',
                price_from: '',
                price_to: '',
                area_min: '',
                area_max: '',
                search: ''
              }
            }
          })} >
            <CitiesHome imgSrc={shengjinImg} cityAlt={'Shengjin'} cityName={'Shengjin'} />
          </div>
        </div>
      </div>
      {/* <div className='py-0 py-md-1' />
      <div className="container-xl my-5">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-5 mb-3 mb-lg-0">
            <AgentOfMonthCard className='rounded-4 flex-column'>
              <AgentImageContainer className='rounded-4 bg-secondary position-relative'>
                <img src={agentBadge} className='img-fluid agent-badge' alt="" />
                <img src={agentImage} alt="Agent Agent" className='rounded-3 agent-image' />
              </AgentImageContainer>
              <AgentName>
                <h3 className='fs-5 fw-bold opacity-75 text-white'>{t('home.agent_of_month')}</h3>
                <h3 className='fs-2 fw-semibold text-white'>Florian Disha</h3>
              </AgentName>
            </AgentOfMonthCard>
          </div>
          <BannerContainer className="col-12 col-md-12 col-lg-7">
            <img src={banner} alt="Baner" className='rounded-3 img-fluid' />
          </BannerContainer>
        </div>
      </div> */}
      <div className='py-0 py-md-1' />
      <div className="container-xl my-5">
        <h2 className='h4 text-center text-primary fw-bold mb-4'>{t('home.our_agents')}</h2>
        <AgentsSlider paginationClass={'swiper-custom-pagination-agents'} agents={agents} />
        <Button buttonClass={'btn-primary d-table mx-auto mt-4 mt-md-5'} buttonText={t('home.all_agents')} onClick={() => navigate('/agents')} />
      </div>
      <div className='py-0 py-md-1' />
    </>
  )
}

export default Home