import React from 'react'
import { AgentCard, AgentImage } from '../screens/Agents.styles'
import { BACKEND_STORAGE_URL } from '../config/urls'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function AgentCardComponent({ agent }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goToAgent = () => {
    let path = localStorage.getItem('language') === 'sq' ? `/agents/${agent.username}` : `/en/agents/${agent.username}`
    navigate(path)
  }

  return (
    <AgentCard className="card rounded-3 position-relative" onClick={() => goToAgent()}>
      <span className="badge bg-primary position-absolute end-0 top-0 mt-2 me-2">
        {agent.agent_detail.agent_title}
      </span>
      <div className="card-body d-flex flex-column justify-content-center align-items-stretch">
        <div className='rounded-3 align-items-stretch d-flex justify-content-center'>
          <img src={BACKEND_STORAGE_URL + agent.profile_image} className='rounded-3' alt={`${agent.name + ' ' + agent.surname}`} />
        </div>
        <h4 className='fw-bold text-center mt-3'>{agent.name + ' ' + agent.surname}</h4>
        <p className='fw-bold text-center m-0'>{agent.properties_count} {t('listed_properties')}</p>
      </div>
    </AgentCard>
  )
}

export default AgentCardComponent