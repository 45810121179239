import React from 'react'
import { CitiesHomeStyles } from './CitiesHome.styles'

function CitiesHome({ imgSrc, cityName, cityAlt }) {
  return (
    <CitiesHomeStyles className='d-flex align-items-stretch h-100 position-relative'>
      <h2 className='h2 text-uppercase fw-bolder text-light position-absolute w-100 h-100 d-flex align-items-center justify-content-center z-3'>{cityName}</h2>
      <img className='img-fluid w-100 rounded-3' src={imgSrc} alt={`${cityAlt} Image`} />
    </CitiesHomeStyles>
  )
}

export default CitiesHome