import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faBars, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import logo from '../assets/images/logo-gold.svg'
import { LogoImg, Navigation } from './Navbar.styles'
import i18n from '../utils/i18n'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'


function Navbar() {
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'sq');
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const handleChangeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    const handleCollapse = () => {
        let nav = document.getElementById('navbarSupportedContent')
        let btn = document.getElementById('navBarBtn')
        nav.classList.remove('show')
        btn.classList.add("collapsed")
    }

    useEffect(() => {
        const pathPrefix = language === 'en' ? '/en' : ''; // Language prefix based on selected language
        const newPath = pathPrefix + location.pathname.replace(/\/(en|sq)/, ''); // Update path with language prefix
        navigate(newPath, { replace: true });
        localStorage.setItem('language', language);
    }, [language, location.pathname, navigate]);

    return (
        <>
            <nav className='bg-secondary'>
                <div className='d-flex px-2 mx-md-4 align-items-center justify-content-between'>
                    <div className='d-none d-md-flex align-items-center gap-2 gap-md-4'>
                        <div className='text-white d-flex align-items-center '>
                            <a href="tel:+355675862122" className='text-decoration-none text-white '>
                                <FontAwesomeIcon icon={faPhone} className='me-1' />
                                +355 67 586 2122
                            </a>
                        </div>
                        <div className='text-white d-flex align-items-center '>
                            <a href="mailto:info@proalbrealestate.com" className='text-decoration-none text-white '>
                                <FontAwesomeIcon icon={faEnvelope} className='me-1' />
                                info@proalbrealestate.com
                            </a>
                        </div>
                    </div>
                    <div className="dropdown py-0">
                        <a className="btn btn-secondary dropdown-toggle text-white" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {language === 'sq' ? "Shqip" : "English"}
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <button className="dropdown-item" onClick={() => handleChangeLanguage(language === 'sq' ? 'en' : 'sq')}>
                                    {language === 'en' ? "Shqip" : "English"}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <Navigation className="navbar navbar-expand-lg bg-primary">
                <div className="container-fluid px-4 d-flex justify-content-between align-items-center">
                    <Link to={language === 'en' ? "/en" : "/"} >
                        <LogoImg>
                            <img src={logo} alt="Proalb Real Estate Logo" />
                        </LogoImg>
                    </Link>
                    <button className="navbar-toggler border-0 order-2 order-lg-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" id='navBarBtn'>
                        <FontAwesomeIcon icon={faBars} style={{ color: "#ffffff", }} size='xl' />
                    </button>
                    <div className="collapse navbar-collapse order-2 order-lg-0" id="navbarSupportedContent">
                        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 text-white">
                            <li className="nav-item">
                                <Link className={`nav-link ${location.pathname === '/' || location.pathname === '/en' ? 'text-secondary' : 'text-white'} fw-bold`} aria-current="page" to={`${language === 'en' ? '/en' : '/'}`} onClick={() => handleCollapse()}>
                                    {t('menu.home')}
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className={`nav-link ${location.pathname.includes('/en/properties') || location.pathname.includes('/properties') ? 'text-secondary' : 'text-white'} fw-bold`} to={`${language === 'en' ? '/en/properties' : '/properties'}`} onClick={() => handleCollapse()}>
                                    {t('menu.properties')}
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className={`nav-link ${location.pathname.includes('/en/agents') || location.pathname.includes('/agents') ? 'text-secondary' : 'text-white'} fw-bold`} to={`${language === 'en' ? '/en/agents' : '/agents'}`} onClick={() => handleCollapse()}>
                                    {t('menu.agents')}
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className={`nav-link ${location.pathname === '/en/about-us' || location.pathname === '/about-us' ? 'text-secondary' : 'text-white'} fw-bold`} to={`${language === 'en' ? '/en/about-us' : '/about-us'}`} onClick={() => handleCollapse()}>
                                    {t('menu.about_us')}
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className={`nav-link ${location.pathname === '/contact' || location.pathname === '/en/contact' ? 'text-secondary' : 'text-white'} fw-bold`} to={`${language === 'en' ? '/en/contact' : '/contact'}`} onClick={() => handleCollapse()}>
                                    {t('menu.contact')}
                                </Link>
                            </li>
                            <li className="nav-item ">
                                <Link className={`nav-link ${location.pathname === '/work-with-us' || location.pathname === '/en/work-with-us' ? 'text-secondary' : 'text-white'} fw-bold`} to={`${language === 'en' ? '/en/work-with-us' : '/work-with-us'}`} onClick={() => handleCollapse()}>
                                    {t('menu.work_with_us')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='ms-auto me-2 ms-lg-0'>
                        <a href="tel:+355675862122" className='text-decoration-none text-white py-2 px-3 rounded-3 btn btn-secondary'>
                            <FontAwesomeIcon icon={faPhone} />
                            <span className='ms-2'>{t('menu.contact_us')}</span></a>
                    </div>
                </div>
            </Navigation >
        </>
    )
}

export default Navbar