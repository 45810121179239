import axe from "../../services/axios-client/axiosClient";
import { generalAlertError } from "../../utils/alert";
import caller from "./agentsCaller";

export const fetchAgents = async (page = null) => {
  caller.fetchAgentsPending();
  try {
    let response = null;

    if (page === null) {
      response = await axe.get("/all-agents");
    }

    if (page !== null) {
      response = await axe.get("/all-agents", {
        params: {
          page: page,
        },
      });
    }

    if (response.status !== 200) {
      caller.fetchAgentsRejected("Error");
      if (localStorage.getItem("language") === "sq") {
        generalAlertError("Dicka shkoi gabim!");
      }
      if (localStorage.getItem("language") === "en") {
        generalAlertError("Something went wrong!");
      }
    }

    if (response.status === 200) {
      caller.fetchAgentsSuccess(response.data.data);
    }
  } catch (error) {
    caller.fetchAgentsRejected("Error");
    if (localStorage.getItem("language") === "sq") {
      generalAlertError("Dicka shkoi gabim!");
    }
    if (localStorage.getItem("language") === "en") {
      generalAlertError("Something went wrong!");
    }
  }
};

export const resetAgentsFetched = () => {
  caller.resetFetchAgents();
};

export const fetchAgent = async (agentUsername, page = null) => {
  caller.fetchAgentPending();
  try {
    let response = null;

    if (page === null) {
      response = await axe.get(`/agent/${agentUsername}`, {
        params: {
          lang: localStorage.getItem("language"),
        },
      });
    }

    if (page !== null) {
      response = await axe.get(`/agent/${agentUsername}`, {
        params: {
          lang: localStorage.getItem("language"),
          page: page,
        },
      });
    }

    if (response.status !== 200) {
      caller.fetchAgentRejected("Error");
      if (localStorage.getItem("language") === "sq") {
        generalAlertError("Dicka shkoi gabim!");
      }
      if (localStorage.getItem("language") === "en") {
        generalAlertError("Something went wrong!");
      }
    }

    if (response.status === 200) {
      caller.fetchAgentSuccess(response.data.data);
    }
  } catch (error) {
    console.log(error);
    caller.fetchAgentRejected("Error");
    if (localStorage.getItem("language") === "sq") {
      generalAlertError("Dicka shkoi gabim!");
    }
    if (localStorage.getItem("language") === "en") {
      generalAlertError("Something went wrong!");
    }
  }
};

export const resetAgentFetched = () => {
  caller.resetFetchAgent();
};
