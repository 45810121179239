import React, { useEffect } from 'react'
import { CardsSection, SingleAgentImg, SingleAgentInfo } from './Agents.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import PropertyCard from '../components/PropertyCard'
import Pagination from '../components/common/Pagination'
import { useParams } from 'react-router-dom'
import { fetchAgent, resetAgentFetched } from '../feature/agents/agentsActions'
import { useSelector } from 'react-redux'
import PageLoader from '../components/common/PageLoader'
import { BACKEND_STORAGE_URL } from '../config/urls'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { faLinkedin, faSquareFacebook, faSquareInstagram, faTiktok, faWhatsapp } from '@fortawesome/free-brands-svg-icons'

function SingleAgent() {
    const { agentUsername } = useParams()
    const { t } = useTranslation()
    const { loading, agent, properties, last_page, page } = useSelector(store => store.agents.agent)

    const collectData = (newPage = null) => {
        if (newPage === null) {
            fetchAgent(agentUsername)
        } else {
            fetchAgent(agentUsername, newPage)
        }
    }

    useEffect(() => {
        collectData()

        return () => {
            resetAgentFetched()
        }

    }, [agentUsername, localStorage.getItem('language')])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (loading) {
        return <PageLoader />
    }

    return (
        <>
            <Helmet>
                <title data-n-head="true">{agent.name + ' ' + agent.surname} | ProALB Realestate Group</title>
                <meta name="description" content="ProALB RealEstate Group është angazhuar të jetë një vlerë e shtuar në sektorin e pasurive të paluajtshme duke ofruar eksperienca të shkëlqyera për të gjithë bashkëpunëtorët tanë." />
                <meta name="keywords" content="proalb, realestate, albania, tirana, prona, ne shitje, qera, apartamente, imobiliare, vila, studjo, biznese, trruall, komerciale, shtepi, shpi ne shitje, shpi me qera, rezidenca banimi, komplekse, ndertesa" />
                <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <meta property="og:image" content={`https://proalbrealestate.com/storage/${agent.profile_image}`} />
            </Helmet>
            <div className='container-xl mb-5'>
                <SingleAgentInfo className="row my-5">
                    <div className="col-12 col-lg-5 position-relative">
                        <span className="badge bg-primary position-absolute end-0 top-0 mt-3 me-3 fs-6">
                            {agent.agent_detail.agent_title}
                        </span>
                        <SingleAgentImg className='rounded-3' >
                            <img src={BACKEND_STORAGE_URL + agent.profile_image} className='rounded-3' alt={`${agent.name + ' ' + agent.surname}`} />
                        </SingleAgentImg>
                    </div>
                    <div className="col-12 col-lg-7 d-flex align-items-center justify-content-start">
                        <div className='ps-md-3'>
                            <p className='text-info mt-4 mt-lg-0 mb-0 '>{agent.properties_count} {t('listed_properties')}</p>
                            <h1 className='fw-medium'>{agent.name + ' ' + agent.surname}</h1>
                            <h6 className='mt-4 mt-xl-5 fw-bold '>{t('about_agent')}:</h6>
                            <p className='pe-5'>
                                {agent.agent_detail.description}
                            </p>
                            <div className='d-flex justify-content-start align-items-start align-items-lg-center gap-2 gap-lg-5 mt-4 flex-column flex-md-row'>
                                <div className='d-flex justify-content-start align-items-center gap-2'>
                                    {agent.agent_detail.facebook_url && <a href={agent.agent_detail.facebook_url} target='_blank'>
                                        <FontAwesomeIcon icon={faSquareFacebook} className='text-secondary' size='xl' />
                                    </a>}
                                    {agent.agent_detail.instagram_url && <a href={agent.agent_detail.instagram_url} target='_blank'>
                                        <FontAwesomeIcon icon={faSquareInstagram} className='text-secondary' size='xl' />
                                    </a>}
                                    {agent.agent_detail.linkedin_url && <a href={agent.agent_detail.linkedin_url} target='_blank'>
                                        <FontAwesomeIcon icon={faLinkedin} className='text-secondary' size='xl' />
                                    </a>}
                                    {agent.agent_detail.tiktok_url && <a href={agent.agent_detail.tiktok_url} target='_blank'>
                                        <FontAwesomeIcon icon={faTiktok} className='text-secondary' size='xl' />
                                    </a>}
                                    {agent.phone && <a href={`https://wa.me/${agent.phone}`} target='_blank'>
                                        <FontAwesomeIcon icon={faWhatsapp} className='text-secondary' size='xl' />
                                    </a>}
                                </div>
                                <div className='d-flex justify-content-start align-items-center gap-2'>
                                    <FontAwesomeIcon icon={faPhone} className='text-secondary' size='xl' />
                                    <h6 className='fw-bold m-0'>
                                        <a href={`mailto:${agent.phone}`} className='text-decoration-none'>{agent.phone}</a>
                                    </h6>
                                </div>
                                <div className='d-flex justify-content-start align-items-center gap-2'>
                                    <FontAwesomeIcon icon={faEnvelope} className='text-secondary' size='xl' />
                                    <h6 className='fw-bold m-0'>
                                        <a href={`mailto:${agent.email}`} className='text-decoration-none'>{agent.email}</a>
                                    </h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </SingleAgentInfo>
                <hr />
                {properties.length === 0 ?
                    <div className="container">
                        <div className='alert alert-info'>
                            {localStorage.getItem("language") === 'sq' ? 'Nuk ka prona te listuara' : 'No Properties Listed'}
                        </div>
                    </div>
                    : <CardsSection className='mt-5 px-4 px-lg-0'>
                        {properties.map((property, index) => (
                            <PropertyCard property={property} key={index} />
                        ))}
                    </CardsSection>
                }
                {last_page !== 1 && <div className='d-flex align-items-center justify-content-center mt-5'>
                    <Pagination last_page={last_page} page={page} collectData={collectData} />
                </div>}
            </div>
        </>
    )
}

export default SingleAgent