import React from 'react'
import { InputTextGroupStyles } from './InputTextGroup.styles'

function InputTextGroup({ inputGroupClass, inputClass, name, id, placeholder, error, value, type, onChange }) {
    return (
        <InputTextGroupStyles className={`${inputGroupClass}`}>
            <input type={type} className={`form-control rounded-3 ${inputClass} ${error ? 'is-invalid' : ""}`} name={name} id={id} placeholder={placeholder} value={value} onChange={(e) => onChange(e)} />
            <div className="invalid-feedback">
                {error}
            </div>
        </InputTextGroupStyles>
    )
}

export default InputTextGroup