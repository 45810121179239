import React from 'react'
import Button from '../components/common/Button'
import { NotFoundStyles } from './NotFound.styles'
import notFound from '../assets/images/bg-404.svg'
import { useTranslation } from 'react-i18next'

function NotFound() {
    const { t } = useTranslation()
    return (
        <NotFoundStyles className='bg-secondary position-relative'>
            <img src={notFound} alt="Nuk u gjet" className='position-absolute img-fluid' />
            <div className='position-relative z-3'>
                <h1 className='text-center text-white mb-5 fw-bold'>{t('not_found_title')}</h1>
                <div className='d-flex justify-content-center align-items-center'>
                    <a href="/" className='text-decoration-none text-white btn btn-primary rounded-3 py-2 px-5 '>{t('not_found_button_text')}</a>
                </div>
            </div>
        </NotFoundStyles>
    )
}

export default NotFound