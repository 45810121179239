import React, { useEffect } from 'react'
import { AgentCards } from './Agents.styles'
import { useTranslation } from 'react-i18next'
import AgentCardComponent from '../components/AgentCardComponent'
import Pagination from '../components/common/Pagination'
import { fetchAgents, resetAgentsFetched } from '../feature/agents/agentsActions'
import { useSelector } from 'react-redux'
import PageLoader from '../components/common/PageLoader'
import { Helmet } from 'react-helmet'

function Agents() {
    const { t } = useTranslation()
    const { loading, agents, last_page, page } = useSelector(store => store.agents.agents)

    useEffect(() => {
        fetchAgents()

        return () => {
            resetAgentsFetched()
        }
    }, [])

    if (loading) {
        return <PageLoader />
    }

    return (
        <>
            {localStorage.getItem('language') === 'sq' ?
                <Helmet>
                    <title>Agjentët Tanë | ProALB Realestate Group</title>
                    <meta name="description" content="ProALB RealEstate Group është angazhuar të jetë një vlerë e shtuar në sektorin e pasurive të paluajtshme duke ofruar eksperienca të shkëlqyera për të gjithë bashkëpunëtorët tanë." />
                    <meta name="keywords" content="proalb, realestate, albania, tirana, prona, ne shitje, qera, apartamente, imobiliare, vila, studjo, biznese, trruall, komerciale, shtepi, shpi ne shitje, shpi me qera, rezidenca banimi, komplekse, ndertesa" />
                </Helmet> :
                <Helmet>
                    <title>Our Agents | ProALB Realestate Group</title>
                    <meta name="description" content="ProALB Real Estate Group is committed to being an added value in the real estate sector by providing excellent experiences to all our associates." />
                    <meta name="keywords" content="proalb, realestate, albania, tirana, property, for sale, rent, apartments, real estate, villas, studios, businesses, plots, commercial, houses, houses for sale, houses for rent, residential residences, complexes, buildings" />
                </Helmet>}
            <h1 className='text-center my-5'>
                {t('home.our_agents')}
            </h1>
            <div className='container-xl mb-5'>
                <AgentCards>
                    {agents.map((agent, index) => (
                        <AgentCardComponent agent={agent} key={index} />
                    ))}
                </AgentCards>
                {last_page !== 1 && <div className='d-flex align-items-center justify-content-center mt-5'>
                    <Pagination last_page={last_page} page={page} collectData={fetchAgents} />
                </div>}
            </div>
        </>

    )
}

export default Agents