import { configureStore } from "@reduxjs/toolkit";
import { agentsSlice } from "./agents/agentsSlice";
import { propertiesSlice } from "./properties/propertiesSlice";
import { homeSlice } from "./home/homeSlice";

export const store = configureStore({
  reducer: {
    agents: agentsSlice.reducer,
    properties: propertiesSlice.reducer,
    home: homeSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
