import React from 'react'
import { InputTextAreaStyles } from './InputTextArea.styles'

function InputTextArea({ textAreaClass, textAreaDivClass, placeholder, name, value, error, onChange }) {
    return (
        <InputTextAreaStyles className={`${textAreaDivClass}`}>
            <textarea className={`form-control rounded-3 ${textAreaClass} ${error ? 'is-invalid' : ""}`} value={value} onChange={(e) => onChange(e)} name={name} rows="3" placeholder={placeholder}></textarea>
            <div className="invalid-feedback">
                {error}
            </div>
        </InputTextAreaStyles>
    )
}

export default InputTextArea