export const LANGUAGES = [
  {
    label: "Shqip",
    code: "sq",
  },
  {
    label: "English",
    code: "en",
  },
];

export const main_types = [
  {
    id: "residential",
    en: "Residential",
    al: "Rezidencial",
    sq: "Rezidencial",
  },
  {
    id: "commercial",
    en: "Commercial",
    al: "Comercial",
    sq: "Comercial",
  },
  {
    id: "land",
    en: "Land",
    al: "Tokë",
    sql: "Tokë",
  },
];

export const business_type = [
  {
    id: "1",
    en: "For Rent",
    al: "Për Qira",
    sq: "Për Qira",
  },
  {
    id: "2",
    en: "For Sale",
    al: "Në Shitje",
    sq: "Në Shitje",
  },
];

export const availability = [
  {
    id: "available",
    sq: "E disponueshme",
    en: "Available",
  },
  {
    id: "reserved",
    sq: "Rezervuar",
    en: "Reserved",
  },
  {
    id: "in_negotiation",
    sq: "Nen negociata",
    en: "In Negotiation",
  },
];

export const property_types = [
  {
    id: "apartment",
    al: "Apartament",
    en: "Apartment",
  },
  {
    id: "dublex",
    al: "Duplex",
    en: "Duplex",
  },
  {
    id: "penthouse",
    al: "Penthouse",
    en: "Penthouse",
  },
  {
    id: "studio",
    al: "Studio",
    en: "Studio",
  },
  {
    id: "building",
    al: "Ndertese",
    en: "Building",
  },
  {
    id: "house",
    al: "Shtepi",
    en: "House",
  },
  {
    id: "villa",
    al: "Vile",
    en: "Villa",
  },
  {
    id: "bungalow",
    al: "Shtepi NjeKateshe",
    en: "Bungalow",
  },
  {
    id: "commercial",
    al: "Commercial",
    en: "Commercial",
  },
  {
    id: "rural_land",
    al: "Rural Land",
    en: "Rural Land",
  },
  {
    id: "industrial_land",
    al: "Industrial Land",
    en: "Industrial Land",
  },
  {
    id: "commercial_land",
    al: "Commercial Land",
    en: "Commercial Land",
  },
  {
    id: "industrial",
    al: "Industrial",
    en: "Industrial",
  },
  {
    id: "office",
    al: "Office",
    en: "Office",
  },
  {
    id: "shop",
    al: "Shop",
    en: "Shop",
  },
  {
    id: "parking",
    al: "Parking",
    en: "Parking",
  },
  {
    id: "multipurpose_space",
    al: "Multipurpose Space",
    en: "Multipurpose Space",
  },
  {
    id: "bar_restaurant",
    al: "Bar / Restaurant",
    en: "Bar / Restaurant",
  },
  {
    id: "hotel",
    al: "Hotel",
    en: "Hotel",
  },
  {
    id: "apart_hotel",
    al: "Apart-Hotel",
    en: "Apart-Hotel",
  },
  {
    id: "warehouse",
    al: "Warehouse",
    en: "Warehouse",
  },
  {
    id: "land",
    al: "Land",
    en: "Land",
  },
  {
    id: "urban_land",
    al: "Urban Land",
    en: "Urban Land",
  },
  {
    id: "pavilion",
    al: "Pavilion",
    en: "Pavilion",
  },
  {
    id: "factory",
    al: "Factory",
    en: "Factory",
  },
  {
    id: "commercial",
    al: "Commercial",
    en: "Commercial",
  },
  {
    id: "beach_area",
    al: "Beach Area",
    en: "Beach Area",
  },
];
