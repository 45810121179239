import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  home: {
    loading: true,
    error: null,
    featured_properties: [],
    latest_properties: [],
    agents: [],
    areas: [],
  },
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    fetchHomeDataPending: (state) => {
      state.home.loading = true;
      state.home.error = null;
    },
    fetchHomeDataRejected: (state, { payload }) => {
      state.home.loading = false;
      state.home.error = payload;
    },
    fetchHomeDataSuccess: (state, { payload }) => {
      state.home.loading = false;
      state.home.featured_properties = payload.data.featuredProperties;
      state.home.latest_properties = payload.data.latestProperties;
      state.home.agents = payload.data.agents;
      state.home.areas = payload.areas;
    },
    resetHomeData: (state) => {
      state.home = { ...initialState.home };
    },
  },
});
