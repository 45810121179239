import axe from "../../services/axios-client/axiosClient";
import { generalAlertError } from "../../utils/alert";
import caller from "./propertiesCaller";

export const fetchProperties = async (page = null) => {
  caller.fetchPropertiesPending();
  try {
    let response = null;

    if (page === null) {
      response = await axe.get("/all-properties", {
        params: {
          lang: localStorage.getItem("language"),
        },
      });
    }

    if (page !== null) {
      response = await axe.get("/all-properties", {
        params: {
          lang: localStorage.getItem("language"),
          page: page,
        },
      });
    }

    if (response.status !== 200) {
      caller.fetchPropertiesRejected("Error");
      if (localStorage.getItem("language") === "sq") {
        generalAlertError("Dicka shkoi gabim!");
      }
      if (localStorage.getItem("language") === "en") {
        generalAlertError("Something went wrong!");
      }
    }

    if (response.status === 200) {
      let data = {
        data: response.data.data.data,
        areas: response.data.data.areas,
        cities: response.data.data.cities,
      };
      caller.fetchPropertiesSuccess(data);
    }
  } catch (error) {
    caller.fetchPropertiesRejected("Error");
    if (localStorage.getItem("language") === "sq") {
      generalAlertError("Dicka shkoi gabim!");
    }
    if (localStorage.getItem("language") === "en") {
      generalAlertError("Something went wrong!");
    }
  }
};

export const searchFetchProperties = async (params) => {
  caller.fetchPropertiesPending();
  try {
    const response = await axe.get("/all-properties-filter", {
      params: {
        ...params,
        lang: localStorage.getItem("language"),
      },
    });

    if (response.status !== 200) {
      caller.fetchPropertiesRejected("Error");
      if (localStorage.getItem("language") === "sq") {
        generalAlertError("Dicka shkoi gabim!");
      }
      if (localStorage.getItem("language") === "en") {
        generalAlertError("Something went wrong!");
      }
    }

    if (response.status === 200) {
      let data = {
        data: response.data.data.data,
        areas: response.data.data.areas,
        cities: response.data.data.cities,
      };
      caller.fetchPropertiesSuccess(data);
    }
  } catch (error) {
    caller.fetchPropertiesRejected("Error");
    if (localStorage.getItem("language") === "sq") {
      generalAlertError("Dicka shkoi gabim!");
    }
    if (localStorage.getItem("language") === "en") {
      generalAlertError("Something went wrong!");
    }
  }
};

export const resetPropertiesFetched = () => {
  caller.fetchPropertiesReset();
};

export const fetchProperty = async (propertySlug) => {
  caller.fetchPropertyPending();
  try {
    const response = await axe.get(`/single-property/${propertySlug}`, {
      params: {
        lang: localStorage.getItem("language"),
      },
    });

    if (response.status !== 200) {
      caller.fetchPropertyRejected("Error");
      if (localStorage.getItem("language") === "sq") {
        generalAlertError("Dicka shkoi gabim!");
      }
      if (localStorage.getItem("language") === "en") {
        generalAlertError("Something went wrong!");
      }
    }

    if (response.status === 200) {
      caller.fetchPropertySuccess(response.data.data);
    }
  } catch (error) {
    caller.fetchPropertyRejected("Error");
    if (localStorage.getItem("language") === "sq") {
      generalAlertError("Dicka shkoi gabim!");
    }
    if (localStorage.getItem("language") === "en") {
      generalAlertError("Something went wrong!");
    }
  }
};

export const resetPropertyFetched = () => {
  caller.fetchPropertyReset();
};


export const incrementViewsCount = async (propertyId) => {
  caller.increaseViewCountPending()
  try {
    await axe.put(`/property-viewed/${propertyId}`)

    caller.increaseViewCountSuccess()
  } catch (error) {
    caller.increaseViewCountRejected('internal error')
  }

}