import styled from "styled-components";
import footer from "../assets/images/footer.svg";
import footerMobile from "../assets/images/footer-mobile.svg";
import footerTablet from "../assets/images/footer-tablet.svg";
import heroSection from "../assets/images/hero-section.png";

export const LogoImg = styled.div`
  img {
    width: 120px;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 100px;
    }
  }
`;
export const Navigation = styled.nav`
  button:focus {
    box-shadow: none !important;
  }

  ul li {
    font-size: 18px;
  }
`;

export const FooterStyles = styled.div`
  background: url(${footer}) center center/cover no-repeat;

  @media screen and (max-width: 768px) {
    background: url(${footerMobile}) center center/cover no-repeat;
  }
  @media (min-width: 769px) and (max-width: 1250px) {
    background: url(${footerTablet}) center center/cover no-repeat;
  }
`;

export const FooterLogo = styled.div`
  img {
    width: 275px;
  }
`;
export const SocialMedias = styled.div`
  /* padding: 11px 13px;
   */
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const HeroSection = styled.div`
  background: url(${heroSection}) center center/cover no-repeat;
  height: 300px;
`;
