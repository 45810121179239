import React from 'react'
import { HeroSection } from './Navbar.styles'

function DefaultHero({ text }) {
    return (
        <HeroSection className='d-flex align-items-center justify-content-start'>
            <div className='p-5'>
                <h1 className='text-white fw-bold'>{text}</h1>
            </div>
        </HeroSection>
    )
}

export default DefaultHero