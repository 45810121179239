import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules';
import PropertyCard from './PropertyCard';

function RelatedPropertiesSlider({ paginationClass, related_properties }) {
  return (
    <>
      {related_properties.length === 0 ? <div className='alert alert-info'>
        {localStorage.getItem("language") === 'sq' ? 'Nuk ka prona te ngjashme' : 'No Related Properties'}
      </div> :
        <>
          <Swiper
            spaceBetween={20}
            autoplay={true}
            loop={true}
            className='py-3 px-2'
            pagination={{
              clickable: true,
              el: `.${paginationClass}`,
            }}
            modules={[Pagination]}
            breakpoints={{
              576: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              578: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              1400: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
          >
            {related_properties.map((property, index) => (
              <SwiperSlide key={index}>
                <PropertyCard property={property} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={`d-flex align-items-center justify-content-center gap-2 mt-4 ${paginationClass}`} />
        </>
      }

    </>
  )
}

export default RelatedPropertiesSlider