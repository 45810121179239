import React from 'react'
import AgentCardComponent from '../AgentCardComponent'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules';

function AgentsSlider({ paginationClass, agents }) {
  return (
    <>
      <Swiper
        spaceBetween={20}
        autoplay={true}
        className='py-3 px-2'
        pagination={{
          clickable: true,
          el: `.${paginationClass}`,
        }}
        modules={[Pagination]}
        breakpoints={{
          576: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          578: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1400: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
        }}
      >
        {agents.map((agent, index) => (
          <SwiperSlide key={index}>
            <AgentCardComponent agent={agent} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={`d-flex align-items-center justify-content-center gap-2 mt-4 ${paginationClass}`} />
    </>
  )
}

export default AgentsSlider