import React, { useEffect, useState } from 'react'
import DefaultHero from '../components/DefaultHero'
import InputTextGroup from '../components/forms/InputTextGroup'
import Button from '../components/common/Button'
import { ShortDesc } from './NotFound.styles'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import axe from '../services/axios-client/axiosClient'
import { generalAlertErrorToast, generalAlertSuccessToast } from '../utils/alert'
import { Helmet } from 'react-helmet'

function WorkWithUs() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)

    const schema = yup.object().shape({
        name: yup.string().required(t('work_with_us.form.validation.name')),
        surname: yup.string().required(t('work_with_us.form.validation.surname')),
        email: yup.string().required().email(t('work_with_us.form.validation.email')),
        phone: yup.string().required(t('work_with_us.form.validation.phone')),
        position: yup.string().required(t('work_with_us.form.validation.position')),
        resume: yup.object().shape({
            filename: yup.string().required(t('work_with_us.form.validation.resume')),
            content: yup.string().required(t('work_with_us.form.validation.resume'))
        })
    });

    const { formState: { errors }, handleSubmit, setValue, watch, reset } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            surname: '',
            email: "",
            phone: "",
            position: '',
            resume: {
                filename: "",
                content: ''
            }
        }
    })

    const updateFormData = (data) => {
        const keys = Object.keys(data);
        keys.forEach((key) => {
            setValue(key, data[key], {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
        });
    };

    const formData = watch()

    const uploadResume = (files) => {
        let file = files[0]
        let baseURL = '';
        let reader = new FileReader();
        reader.readAsDataURL(file);
        const fileType = file.type;
        reader.onload = () => {
            baseURL = reader.result;
            updateFormData({
                resume: {
                    filename: file.name,
                    content: baseURL.replace('data:' + fileType + ';base64,', '')
                }
            })
        };
    };


    const submitAplication = async () => {
        try {
            setLoading(true)
            const response = await axe.post('/job-application', formData)

            if (response.status !== 200) {
                setLoading(false)
                if (localStorage.getItem('language') === 'sq') {
                    generalAlertErrorToast('Dicka shkoi gabim! Mesazhi nuk u dergua!')
                }
                if (localStorage.getItem('language') === 'en') {
                    generalAlertErrorToast('Something went wrong! Message was not sent!')
                }
            }

            if (response.status === 200) {
                setLoading(false)
                if (localStorage.getItem('language') === 'sq') {
                    generalAlertSuccessToast('Mesazhi u dergua me sukses!')
                }
                if (localStorage.getItem('language') === 'en') {
                    generalAlertSuccessToast('Message was succesfully sent!')
                }
                reset()
                document.getElementById('resumeFile').value = ''
            }
        } catch (error) {
            setLoading(false)
            if (localStorage.getItem('language') === 'sq') {
                generalAlertErrorToast('Dicka shkoi gabim! Mesazhi nuk u dergua!')
            }
            if (localStorage.getItem('language') === 'en') {
                generalAlertErrorToast('Something went wrong! Message was not sent!')
            }
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            {localStorage.getItem('language') === 'sq' ?
                <Helmet>
                    <title data-n-head="true">Punoni Me Ne | ProALB Realestate Group</title>
                    <meta name="description" content=" Vlerësojmë profesionistët e kualifikuar të fushës dhe besnikët e përkushtuar si asetin tonë më të çmuar. Bashkojuni grupit tonë të agjentëve dhe ndërtoni të ardhmen tuaj." />
                    <meta property="og:url" content="https://proalbrealestate.com/work-with-us" />
                    <meta name="keywords" content="punoni me ne, aplikoni, puno, proalb, realestate, albania, tirana, prona, ne shitje, qera, apartamente, imobiliare, vila, studjo, biznese, trruall, komerciale, shtepi, shpi ne shitje, shpi me qera, rezidenca banimi, komplekse" />
                    <meta property="og:locale" content="sq_AL" />
                    <meta property="og:type" content="article" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta property="og:image" content="https://proalbrealestate.com/images/icon.png" />
                </Helmet> :
                <Helmet>
                    <title data-n-head="true">Work With Us | ProALB Realestate Group</title>
                    <meta name="description" content="We value qualified field professionals and dedicated loyalists as our most valuable asset. Join our group of agents and build your future." />
                    <meta property="og:url" content="https://proalbrealestate.com/en/work-with-us" />
                    <meta name="keywords" content="workwithus, work, with, us, proalb, realestate, albania, tirana, property, for sale, rent, apartments, real estate, villas, studios, businesses, plots, commercial, houses, houses for sale, houses for rent, residential residences, complexes, buildings" />
                    <meta property="og:locale" content="sq_en" />
                    <meta property="og:type" content="article" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta property="og:image" content="https://proalbrealestate.com/images/icon.png" />
                </Helmet>}
            <DefaultHero text={t('work_with_us.title')} />
            <div className='container'>
                <ShortDesc className='custom-text'>
                    <h5 className='text-center fw-lighter mt-5 '>
                        {t('work_with_us.desc')}
                    </h5>
                </ShortDesc>
                <div className="row mx-auto mt-5">
                    <div className="col-12 offset-0 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                        <div className="card py-3 px-3 p-lg-4 py-xl-4 px-xl-5 mb-5">
                            <div className="card-body">
                                <form onSubmit={handleSubmit(submitAplication)}>
                                    <InputTextGroup
                                        placeholder={t('work_with_us.form.name')}
                                        name={'name'}
                                        value={formData.name}
                                        error={errors.name?.message}
                                        inputGroupClass={'mt-2'}
                                        onChange={(e) => {
                                            updateFormData({
                                                name: e.target.value
                                            })
                                        }}
                                    />
                                    <InputTextGroup
                                        placeholder={t('work_with_us.form.surname')}
                                        name={'surname'}
                                        value={formData.surname}
                                        inputGroupClass={'mt-2'}
                                        onChange={(e) => {
                                            updateFormData({
                                                surname: e.target.value
                                            })
                                        }}
                                        error={errors.surname?.message}
                                    />
                                    <InputTextGroup
                                        placeholder={t('work_with_us.form.phone')}
                                        name={'phone'}
                                        value={formData.phone}
                                        inputGroupClass={'mt-2'}
                                        onChange={(e) => {
                                            updateFormData({
                                                phone: e.target.value
                                            })
                                        }}
                                        error={errors.phone?.message}
                                    />
                                    <InputTextGroup
                                        placeholder={'Email'}
                                        name={'email'}
                                        value={formData.email}
                                        inputGroupClass={'mt-2'}
                                        onChange={(e) => {
                                            updateFormData({
                                                email: e.target.value
                                            })
                                        }}
                                        error={errors.email?.message}
                                    />
                                    <InputTextGroup
                                        placeholder={t('work_with_us.form.position')}
                                        name={'position'}
                                        value={formData.position}
                                        inputGroupClass={'mt-2'}
                                        onChange={(e) => {
                                            updateFormData({
                                                position: e.target.value
                                            })
                                        }}
                                        error={errors.position?.message}
                                    />
                                    <div className="mt-3">
                                        <label htmlFor="formFile" className="form-label fw-light">{t('work_with_us.form.cv')}</label>
                                        <input
                                            className={`form-control ${errors.resume?.content?.message ? 'is-invalid' : ""}`}
                                            name='resume' type="file"
                                            id='resumeFile'
                                            onChange={(e) => {
                                                uploadResume(e.target.files)
                                            }}
                                        />
                                        <div className="invalid-feedback">
                                            {errors.resume?.content?.message}
                                        </div>
                                    </div>
                                    {loading ? <button className="btn btn-primary w-100 mt-3" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                        <span role="status"> {t('work_with_us.form.button')}</span>
                                    </button> : <Button type={'submit'} buttonText={t('work_with_us.form.button')} buttonClass={'w-100 btn-primary mt-3 fw-bold'} />}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default WorkWithUs