import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  properties: {
    loading: true,
    error: null,
    properties: [],
    page: 1,
    last_page: 1,
    total_properties: 0,
    areas: [],
    cities: [],
  },
  property: {
    loading: true,
    error: null,
    property: null,
    related_properties: [],
    isLoadingCount: true
  },
};

export const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    fetchPropertiesPending: (state) => {
      state.properties.loading = true;
      state.properties.error = null;
    },
    fetchPropertiesRejected: (state, { payload }) => {
      state.properties.loading = false;
      state.properties.error = payload;
    },
    fetchPropertiesSuccess: (state, { payload }) => {
      state.properties.loading = false;
      state.properties.properties = payload.data.data;
      state.properties.page = payload.data.current_page;
      state.properties.last_page = payload.data.last_page;
      state.properties.total_properties = payload.data.total;
      state.properties.areas = payload.areas;
      state.properties.cities = payload.cities;
    },
    fetchPropertiesReset: (state) => {
      state.properties = { ...initialState.properties };
    },
    fetchPropertyPending: (state) => {
      state.property.loading = true;
      state.property.error = null;
    },
    fetchPropertyRejected: (state, { payload }) => {
      state.property.loading = false;
      state.property.error = payload;
    },
    fetchPropertySuccess: (state, { payload }) => {
      state.property.loading = false;
      state.property.property = payload.property;
      state.property.related_properties = payload.relatedProperties;
    },
    fetchPropertyReset: (state) => {
      state.property = { ...initialState.property };
    },
    increaseViewCountPending: (state) => {
      state.property.isLoadingCount = true;
      state.property.error = null;
    },
    increaseViewCountRejected: (state, { payload }) => {
      state.property.isLoadingCount = false;
      state.property.error = payload;
    },
    increaseViewCountSuccess: (state) => {
      state.property.isLoadingCount = false;
    },
  },
});
