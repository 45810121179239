export const axeRequestInterceptor = async (config) => {
  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json";
  }

  if (!config.headers.Accept) {
    config.headers.Accept = "application/json";
  }

  config.validateStatus = (status) => status !== 403 && status < 500;

  return config;
};
