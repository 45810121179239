import axe from "../../services/axios-client/axiosClient";
import { generalAlertError } from "../../utils/alert";
import caller from "./homeCaller";

export const fetchHomeData = async () => {
  caller.fetchHomeDataPending();
  try {
    const response = await axe.get("/homepage", {
      params: {
        lang: localStorage.getItem("language"),
      },
    });

    if (response.status !== 200) {
      caller.fetchHomeDataRejected("Error");
      if (localStorage.getItem("language") === "sq") {
        generalAlertError("Dicka shkoi gabim!");
      }
      if (localStorage.getItem("language") === "en") {
        generalAlertError("Something went wrong!");
      }
    }

    if (response.status === 200) {
      let data = {
        data: response.data.data,
        areas: response.data.data.areas,
      };
      caller.fetchHomeDataSuccess(data);
    }
  } catch (error) {
    caller.fetchHomeDataRejected("Error");
    if (localStorage.getItem("language") === "sq") {
      generalAlertError("Dicka shkoi gabim!");
    }
    if (localStorage.getItem("language") === "en") {
      generalAlertError("Something went wrong!");
    }
  }
};

export const resetHomeDataFetched = () => {
  caller.resetHomeData();
};
