import React, { useState } from 'react'
import logo from '../assets/images/logo-beige.svg'
import { FooterLogo, FooterStyles, SocialMedias } from './Navbar.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF, faInstagram, faLinkedinIn, faTiktok } from '@fortawesome/free-brands-svg-icons'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

function Footer() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'sq');
    return (
        <FooterStyles className='bg-black pt-3 pt-lg-5'>
            <div className="container py-5">
                <div className="row">
                    <div className="col-12 col-xl-3">
                        <FooterLogo className='d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start'>
                            <Link to={'/'}>
                                <img src={logo} alt="Proalb Real Estate Group Logo" />
                            </Link>
                        </FooterLogo>
                    </div>
                    <div className="col-12 col-md-6 col-xl-2 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start  mt-5 mt-lg-3">
                        <h5 className='text-white fw-medium mb-3'>
                            {t('footer.navigation.title')}
                        </h5>
                        <ul className="list-unstyled my-0 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start ">
                            <li>
                                <Link to={`${language === 'en' ? '/en' : '/'}`} className='text-decoration-none text-white'>
                                    {t('footer.navigation.home')}
                                </Link>
                            </li>
                            <li>
                                <Link to={`${language === 'en' ? '/en/about-us' : '/about-us'}`} className='text-decoration-none text-white'>
                                    {t('footer.navigation.about')}
                                </Link>
                            </li>
                            <li>
                                <Link to={`${language === 'en' ? '/en/agents' : '/agents'}`} className='text-decoration-none text-white'>
                                    {t('footer.navigation.agents')}
                                </Link>
                            </li>
                            <li>
                                <Link to={`${language === 'en' ? '/en/contact' : '/contact'}`} className='text-decoration-none text-white'>
                                    {t('footer.navigation.contact')}
                                </Link>
                            </li>
                            <li>
                                <Link to={`${language === 'en' ? '/en/work-with-us' : '/work-with-us'}`} className='text-decoration-none text-white'>
                                    {t('footer.navigation.work_with_us')}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-xl-2 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start mt-5 mt-lg-3">
                        <h5 className='text-white fw-medium mb-3'>
                            {t('footer.categories.title')}
                        </h5>
                        <ul className="list-unstyled my-0 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start ">
                            <li className='text-white' style={{ cursor: 'pointer' }} onClick={() => navigate('/properties', {
                                state: {
                                    params: {
                                        business_type: '',
                                        property_type: 'apartment',
                                        city_id: '',
                                        area_id: '',
                                        bedrooms: '',
                                        bathrooms: '',
                                        price_from: '',
                                        price_to: '',
                                        area_min: '',
                                        area_max: '',
                                        search: ''
                                    }
                                }
                            })}>
                                {t('footer.categories.apartments')}
                            </li>
                            <li className='text-white' style={{ cursor: 'pointer' }} onClick={() => navigate('/properties', {
                                state: {
                                    params: {
                                        business_type: '',
                                        property_type: 'villa',
                                        city_id: '',
                                        area_id: '',
                                        bedrooms: '',
                                        bathrooms: '',
                                        price_from: '',
                                        price_to: '',
                                        area_min: '',
                                        area_max: '',
                                        search: ''
                                    }
                                }
                            })}>
                                {t('footer.categories.vila')}

                            </li>
                            <li className='text-white' style={{ cursor: 'pointer' }} onClick={() => navigate('/properties', {
                                state: {
                                    params: {
                                        business_type: '',
                                        property_type: 'commercial',
                                        city_id: '',
                                        area_id: '',
                                        bedrooms: '',
                                        bathrooms: '',
                                        price_from: '',
                                        price_to: '',
                                        area_min: '',
                                        area_max: '',
                                        search: ''
                                    }
                                }
                            })}>
                                {t('footer.categories.commercial')}
                            </li>
                            <li className='text-white' style={{ cursor: 'pointer' }} onClick={() => navigate('/properties', {
                                state: {
                                    params: {
                                        business_type: '',
                                        property_type: 'land',
                                        city_id: '',
                                        area_id: '',
                                        bedrooms: '',
                                        bathrooms: '',
                                        price_from: '',
                                        price_to: '',
                                        area_min: '',
                                        area_max: '',
                                        search: ''
                                    }
                                }
                            })}>
                                {t('footer.categories.land')}
                            </li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start mt-5 mt-xl-3">
                        <h5 className='text-white fw-medium mb-3'>
                            {t('footer.contact.title')}
                        </h5>
                        <ul className="list-unstyled my-0 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start ">
                            <li className='text-white d-flex align-items-center gap-2 mb-3'>
                                <FontAwesomeIcon icon={faPhone} className='text-light' size='lg' />
                                +355 67 586 2122
                            </li>
                            <li className='text-white d-flex align-items-center gap-2 mb-3'>
                                <FontAwesomeIcon icon={faEnvelope} className='text-light' size='lg' />
                                info@proalbrealestate.com
                            </li>
                            <li className='text-white d-flex align-items-center gap-2 mb-3 px-2 px-md-0 ms-4 ms-md-0'>
                                <FontAwesomeIcon icon={faLocationDot} className='text-light' size='lg' />
                                Rruga e Kavajes Rezidenca Garden Building Kati 2
                            </li>
                        </ul>

                    </div>
                    <div className="col-12 col-md-6 col-xl-2 d-flex flex-column justify-content-center align-items-center justify-content-xl-start align-items-xl-start mt-5 mt-lg-0 mt-xl-3">
                        <h5 className='text-white fw-medium mb-3'>
                            {t('footer.social.title')}
                        </h5>
                        <div className='d-flex justify-content-center align-items-center gap-3 mt-3'>
                            <a href="" className='text-decoration-none text-black'>
                                <SocialMedias className='bg-light d-flex align-items-center justify-content-center'>
                                    <FontAwesomeIcon icon={faFacebookF} size='lg' />
                                </SocialMedias>
                            </a>
                            <a href="https://www.instagram.com/proalb.realestategroup/" target='_blank' className='text-decoration-none text-black'>
                                <SocialMedias className='bg-light d-flex align-items-center justify-content-center'>
                                    <FontAwesomeIcon icon={faInstagram} size='lg' />
                                </SocialMedias>
                            </a>
                            <a href="" className='text-decoration-none text-black'>
                                <SocialMedias className='bg-light d-flex align-items-center justify-content-center'>
                                    <FontAwesomeIcon icon={faLinkedinIn} size='lg' />
                                </SocialMedias>
                            </a>
                            <a href="" className='text-decoration-none text-black'>
                                <SocialMedias className='bg-light d-flex align-items-center justify-content-center'>
                                    <FontAwesomeIcon icon={faTiktok} size='lg' />
                                </SocialMedias>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center p-2 text-light">
                © {new Date().getFullYear()} - {t('footer.copyright')}{' '}
                <Link className="text-decoration-none text-light" to="/">
                    Proalb Realestate Group
                </Link>
            </div>
        </FooterStyles>
    )
}

export default Footer