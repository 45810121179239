import styled from "styled-components";

export const ResultsCard = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 551px) and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
