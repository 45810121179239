import React from 'react'
import DefaultHero from '../components/DefaultHero'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules';
import img from '../assets/images/about_slider_img.png'
import { Helmet } from 'react-helmet'

function AboutUs() {
    const { t } = useTranslation()
    return (
        <>
            {localStorage.getItem('language') === 'sq' ?
                <Helmet>
                    <title data-n-head="true">Rreth Nesh | ProALB Realestate Group | Qira, Shitje</title>
                    <meta name="description" content=" ProALB RealEstate Group është angazhuar të jetë një vlerë e shtuar në sektorin e pasurive të paluajtshme duke ofruar eksperienca të shkëlqyera për të gjithë bashkëpunëtorët tanë." />
                    <meta property="og:url" content="https://proalbrealestate.com/about-us" />
                    <meta name="keywords" content="proalb, realestate, albania, tirana, prona, ne shitje, qera, apartamente, imobiliare, vila, studjo, biznese, trruall, komerciale, shtepi, shpi ne shitje, shpi me qera, rezidenca banimi, komplekse, ndertesa" />
                    <meta property="og:locale" content="sq_AL" />
                    <meta property="og:type" content="article" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta property="og:image" content="https://proalbrealestate.com/icon.png" />
                </Helmet> :
                <Helmet>
                    <title data-n-head="true">About Us | ProALB Realestate Group | Rent, Sale</title>
                    <meta name="description" content="ProALB Real Estate Group is committed to being an added value in the real estate sector by providing excellent experiences to all our associates." />
                    <meta property="og:url" content="https://proalbrealestate.com/en/about-us" />
                    <meta name="keywords" content="proalb, realestate, albania, tirana, property, for sale, rent, apartments, real estate, villas, studios, businesses, plots, commercial, houses, houses for sale, houses for rent, residential residences, complexes, buildings" />
                    <meta property="og:locale" content="sq_en" />
                    <meta property="og:type" content="article" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta property="og:image" content="https://proalbrealestate.com/icon.png" />
                </Helmet>}

            <DefaultHero text={t('about.title')} />
            <div className='container'>
                <div className="row mb-5">
                    <div className="col-12 col-md-12 mt-5 pe-2">
                        <h6 className='fw-bold ps-2 ps-md-0 pe-md-0 mb-0'>
                            {t('contact.profile')}
                        </h6>
                        <p className='mt-3'>
                            {t('contact.profile.text')}
                        </p>
                        <h6 className='fw-bold ps-2 ps-md-0 pe-md-0 mb-0'>
                            {t('contact.vision')}
                        </h6>
                        <p className='mt-3'>
                            {t('contact.vision.text')}
                        </p>
                    </div>
                    <div className="col-12 col-md-12 mt-5 ps-2">
                        <h6 className='fw-bold ps-2 ps-md-0 pe-md-0 mb-0'>
                            {t('contact.mission')}
                        </h6>
                        <p className='mt-3'>
                            {t('contact.mission.text')}
                        </p>
                        <h6 className='fw-bold ps-2 ps-md-0 pe-md-0 mb-0'>
                            {t('contact.values')}
                        </h6>
                        <p className='mt-3'>
                            {t('contact.values.text')}
                        </p>
                    </div>
                </div>
                {/* <div className="row mt-2 mb-5">
                    <Swiper
                        spaceBetween={20}
                        autoplay={true}
                        loop={true}
                        pagination={{
                            clickable: true,
                            el: `.swiper-custom-pagination-about`,
                        }}
                        modules={[Pagination]}
                        breakpoints={{
                            576: {
                                slidesPerView: 1,
                                spaceBetween: 40,
                            },
                            578: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1200: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            1400: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <img src={img} className='img-fluid rounded-3' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={img} className='img-fluid rounded-3' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={img} className='img-fluid rounded-3' />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={img} className='img-fluid rounded-3' />
                        </SwiperSlide>
                    </Swiper>
                    <div className={`d-flex align-items-center justify-content-center gap-2 mt-4 swiper-custom-pagination-about`} />
                </div> */}
            </div>
        </>
    )
}

export default AboutUs