import React, { useEffect, useRef, useState } from 'react'
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { LeftArrow, RightArrow } from './PropertyGallery.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { BACKEND_STORAGE_URL } from '../config/urls';

function PropertyGallery({ gallery, cover }) {
  const imageRef = useRef(null)
  const [galleryItems, setGalleryItems] = useState([])

  useEffect(() => {
    let tempArr = []
    tempArr.push({
      original: BACKEND_STORAGE_URL + cover,
      thumbnail: BACKEND_STORAGE_URL + cover
    })

    gallery.map(item => {
      tempArr.push({
        original: BACKEND_STORAGE_URL + item.image_path,
        thumbnail: BACKEND_STORAGE_URL + item.image_path
      })
    })

    setGalleryItems(tempArr)

  }, [gallery, cover])

  return (
    <ImageGallery additionalClass='gallery-custom' ref={imageRef} onClick={() => imageRef.current.toggleFullScreen()} showFullscreenButton={false} items={galleryItems} showPlayButton={false} renderLeftNav={(onClick, disabled) => (
      <LeftArrow onClick={onClick} disabled={disabled}>
        <FontAwesomeIcon icon={faChevronLeft} />
      </LeftArrow>
    )} renderRightNav={(onClick, disabled) => (
      <RightArrow onClick={onClick} disabled={disabled}>
        <FontAwesomeIcon icon={faChevronRight} />
      </RightArrow>
    )} />
  )
}

export default PropertyGallery