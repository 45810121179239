import Swal from "sweetalert2";

import "sweetalert2/dist/sweetalert2.min.css";

export const generalAlertSuccessToast = (message) => {
  Swal.fire({
    icon: "success",
    title: "Success",
    toast: true,
    position: "bottom-right",
    timer: 6000,
    timerProgressBar: true,
    showConfirmButton: false,
    text: message,
  });
};

export const generalAlertErrorToast = (message) => {
  Swal.fire({
    icon: "error",
    title: "error",
    toast: true,
    position: "bottom-right",
    timer: 6000,
    timerProgressBar: true,
    showConfirmButton: false,
    text: message,
  });
};

export const generalAlertSuccess = (message) => {
  Swal.fire({
    icon: "success",
    title: "Success",
    text: message,
  });
};

export const generalAlertError = (message, errorTitle) => {
  Swal.fire({
    icon: "error",
    title: errorTitle ?? "Error",
    text: message,
  });
};

export const confirmAlert = (message, action) => {
  Swal.fire({
    title: "Je i sigurte?",
    text: message,
    type: "warning",
    showCancelButton: true,
    confirmButtonColor: "#DD6B55",
    confirmButtonText: "Po, Jam i sigurte!",
    cancelButtonText: "Jo!",
    closeOnConfirm: false,
    closeOnCancel: false,
  }).then(function (isConfirm) {
    if (isConfirm.isConfirmed) {
      action();
    }
  });
};
