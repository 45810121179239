import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { GlobalStyle } from './styles/global';
import Base from './layouts/Base';
import NotFound from './screens/NotFound';
import WorkWithUs from './screens/WorkWithUs';
import ContactUs from './screens/ContactUs';
import AboutUs from './screens/AboutUs';
import Agents from './screens/Agents';
import i18n from './utils/i18n';
import { useEffect } from 'react';
import Home from "./screens/Home/Home";
import SingleAgent from "./screens/SingleAgent";
import ResultPage from "./screens/ResultPage";
import SingleProperty from "./screens/SingleProperty";
import SinglePropertyShare from "./screens/SinglePropertyShare";



function App() {

  const router = createBrowserRouter([
    {
      path: "/:lang?",
      element: <Base />,
      children: [
        {
          path: '',
          element: <Home />
        },
        {
          path: 'work-with-us',
          element: <WorkWithUs />
        },
        {
          path: 'contact',
          element: <ContactUs />
        },
        {
          path: 'about-us',
          element: <AboutUs />
        },
        {
          path: 'agents',
          element: <Agents />
        },
        {
          path: 'agents/:agentUsername',
          element: <SingleAgent />
        },
        {
          path: 'properties',
          element: <ResultPage />
        },
        {
          path: 'properties/:propertySlug',
          element: <SingleProperty />
        },
        {
          path: 'properties/:propertySlug/share',
          element: <SinglePropertyShare />
        },
        {
          path: '*',
          element: <NotFound />
        },
      ]
    }
  ])

  useEffect(() => {
    const handleLanguageChange = () => {
      localStorage.setItem('language', i18n.language);
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);


  return (
    <>
      <GlobalStyle />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
