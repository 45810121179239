import React from 'react'
import { PageLoaderStyles } from './PageLoader.styles'

function PageLoader() {
  return (
    <PageLoaderStyles>
      <div className="spinner-border text-primary" style={{ "width": '3rem', height: "3rem" }} role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </PageLoaderStyles>
  )
}

export default PageLoader