import React, { useEffect, useState } from 'react'
import ReactSelectCustom from '../components/forms/ReactSelectCustom'
import InputTextGroup from '../components/forms/InputTextGroup'
import filter from '../assets/icons/filter.svg'
import Button from '../components/common/Button'
import PropertyCard from '../components/PropertyCard'
import { ResultsCard } from './ResultPage.styles'
import Pagination from '../components/common/Pagination'
import { useTranslation } from 'react-i18next'
import PageLoader from '../components/common/PageLoader'
import { useSelector } from 'react-redux'
import { fetchProperties, searchFetchProperties } from '../feature/properties/propertiesActions'
import { property_types } from '../constants'
import { main_types } from '../constants'
import { Helmet } from 'react-helmet'

import { useLocation } from 'react-router-dom'

function ResultPage() {
    const [show, setShow] = useState(false)
    const location = useLocation()
    const { t } = useTranslation()
    const { loading, properties, last_page, total_properties, areas, cities, page } = useSelector(store => store.properties.properties)
    const [searchParams, setSearchParams] = useState({
        business_type: '',
        property_type: '',
        main_type: '',
        city_id: '',
        area_id: '',
        bedrooms: '',
        bathrooms: '',
        price_from: '',
        price_to: '',
        area_min: '',
        area_max: '',
        search: ''
    })

    const closeFilter = () => {
        setShow(!show)
    }

    const searchProperties = () => {
        searchFetchProperties(searchParams)
        setShow(false)
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (location.state !== null) {
            setSearchParams(location.state.params);
            searchFetchProperties(location.state.params);
        } else if (location.state === null) {
            fetchProperties();
        }

    }, [localStorage.getItem('language')])

    if (loading) {
        return <PageLoader />
    }

    return (
        <>
            {localStorage.getItem('language') === 'sq' ?
                <Helmet>
                    <title>Qira, Shitje | Apartamente, Vila, Biznes | ProALB Realestate Group</title>
                    <meta name="description" content="ProALB RealEstate Group është angazhuar të jetë një vlerë e shtuar në sektorin e pasurive të paluajtshme duke ofruar eksperienca të shkëlqyera për të gjithë bashkëpunëtorët tanë." />
                    <meta name="keywords" content="proalb, realestate, albania, tirana, prona, ne shitje, qera, apartamente, imobiliare, vila, studjo, biznese, trruall, komerciale, shtepi, shpi ne shitje, shpi me qera, rezidenca banimi, komplekse, ndertesa" />
                </Helmet> :
                <Helmet>
                    <title>Rent, Sale | Apartments, Villas, Business | ProALB Realestate Group</title>
                    <meta name="description" content="ProALB Real Estate Group is committed to being an added value in the real estate sector by providing excellent experiences to all our associates." />
                    <meta name="keywords" content="proalb, realestate, albania, tirana, property, for sale, rent, apartments, real estate, villas, studios, businesses, plots, commercial, houses, houses for sale, houses for rent, residential residences, complexes, buildings" />
                </Helmet>}
            <div className="container-xl my-5">
                <div className="row">
                    <div className="col-12 col-lg-4 col-xl-3">
                        <div className="card p-2 rounded-3">
                            <div className="card-body d-none d-lg-block">
                                <div className='border-bottom d-flex justify-content-between align-items-center p-2' >
                                    <p className='m-0 fw-bold text-dark'>
                                        {t('properties.result.search_title')}
                                    </p>
                                    <img src={filter} alt="Kerkim i Avancuar" />
                                </div>
                                <div>
                                    <ReactSelectCustom options={main_types.map(typ => ({ value: typ.id, label: localStorage.getItem("language") === 'sq' ? typ.al : typ.en }))} placeholder={t('properties.result.main_type')}
                                        onChange={(obj) => setSearchParams({
                                            ...searchParams,
                                            main_type: obj ? obj.value : ''
                                        })}
                                        values={searchParams.main_type}
                                    />
                                    <ReactSelectCustom options={[{ value: 1, label: localStorage.getItem('language') === 'sq' ? 'Për Qira' : 'For Rent' }, { value: 2, label: localStorage.getItem('language') === 'sq' ? 'Për Shitje' : 'For Sale' }]} placeholder={localStorage.getItem('language') === 'sq' ? 'Qira/Shitje' : 'Rent/Sale'}
                                        onChange={(obj) => setSearchParams({
                                            ...searchParams,
                                            business_type: obj ? obj.value : ''
                                        })}
                                        values={searchParams.business_type}
                                    />
                                    <ReactSelectCustom options={property_types.map(typ => ({ value: typ.id, label: localStorage.getItem("language") === 'sq' ? typ.al : typ.en }))} placeholder={t('properties.result.property_category')}
                                        onChange={(obj) => setSearchParams({
                                            ...searchParams,
                                            property_type: obj ? obj.value : ''
                                        })}
                                        values={searchParams.property_type}
                                    />
                                    <ReactSelectCustom
                                        options={cities.map(cit => ({ value: cit.id, label: cit.name }))}
                                        placeholder={t('properties.result.city')}
                                        onChange={(obj) => setSearchParams({
                                            ...searchParams,
                                            city_id: obj ? obj.value : ''
                                        })}
                                        values={searchParams.city_id}
                                    />
                                    <ReactSelectCustom options={areas.map(area => ({ value: area.id, label: area.name }))} placeholder={t('properties.result.zone')}
                                        onChange={(obj) => setSearchParams({
                                            ...searchParams,
                                            area_id: obj ? obj.value : ''
                                        })}
                                        values={searchParams.area_id}
                                    />
                                    <InputTextGroup placeholder={t('properties.result.bedrooms')}
                                        type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                            ...searchParams,
                                            bedrooms: e.target.value
                                        })}
                                        value={searchParams.bedrooms}
                                    />
                                    <InputTextGroup placeholder={t('properties.result.bathrooms')}
                                        type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                            ...searchParams,
                                            bathrooms: e.target.value
                                        })}
                                        value={searchParams.bathrooms}
                                    />
                                    <InputTextGroup placeholder={t('properties.result.total_area_min')}
                                        type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                            ...searchParams,
                                            area_min: e.target.value
                                        })}
                                        value={searchParams.area_min}
                                    />
                                    <InputTextGroup placeholder={t('properties.result.total_area_max')}
                                        type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                            ...searchParams,
                                            area_max: e.target.value
                                        })}
                                        value={searchParams.area_max}
                                    />
                                    <InputTextGroup placeholder={t('properties.result.price_min')}
                                        type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                            ...searchParams,
                                            price_from: e.target.value
                                        })}
                                        value={searchParams.price_from}
                                    />
                                    <InputTextGroup placeholder={t('properties.result.price_max')}
                                        type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                            ...searchParams,
                                            price_to: e.target.value
                                        })}
                                        value={searchParams.price_to}
                                    />
                                    <Button onClick={() => searchProperties()} buttonText={t('properties.result.button')} buttonClass={'mt-2 w-100 btn-primary fw-bold'} />
                                </div>
                            </div>
                            <div className="card-body p-0 d-block d-lg-none">
                                <div className='d-flex justify-content-start align-items-center gap-2' onClick={() => closeFilter()}>
                                    <img src={filter} alt="Kerkim i Avancuar" />
                                    <p className='m-0 fw-bold text-dark'>
                                        {t('properties.result.search_title')}
                                    </p>
                                </div>
                                {show &&
                                    <div className='mt-3'>
                                        <ReactSelectCustom options={main_types.map(typ => ({ value: typ.id, label: localStorage.getItem("language") === 'sq' ? typ.al : typ.en }))} placeholder={t('properties.result.main_type')}
                                            onChange={(obj) => setSearchParams({
                                                ...searchParams,
                                                main_type: obj ? obj.value : ''
                                            })}
                                            values={searchParams.main_type}
                                        />
                                        <ReactSelectCustom options={[{ value: 1, label: localStorage.getItem('language') === 'sq' ? 'Për Qira' : 'For Rent' }, { value: 2, label: localStorage.getItem('language') === 'sq' ? 'Për Shitje' : 'For Sale' }]} placeholder={localStorage.getItem('language') === 'sq' ? 'Qira/Shitje' : 'Rent/Sale'}
                                            onChange={(obj) => setSearchParams({
                                                ...searchParams,
                                                business_type: obj ? obj.value : ''
                                            })}
                                            values={searchParams.business_type}
                                        />
                                        <ReactSelectCustom options={property_types.map(typ => ({ value: typ.id, label: localStorage.getItem("language") === 'sq' ? typ.al : typ.en }))} placeholder={t('properties.result.property_category')} onChange={(obj) => setSearchParams({
                                            ...searchParams,
                                            property_type: obj ? obj.value : ''
                                        })}
                                            values={searchParams.property_type}
                                        />
                                        <ReactSelectCustom options={cities.map(cit => ({ value: cit.id, label: cit.name }))} placeholder={t('properties.result.city')} onChange={(obj) => setSearchParams({
                                            ...searchParams,
                                            city_id: obj ? obj.value : ''
                                        })}
                                            values={searchParams.city_id}
                                        />
                                        <ReactSelectCustom options={areas.map(area => ({ value: area.id, label: area.name }))} placeholder={t('properties.result.zone')} onChange={(obj) => setSearchParams({
                                            ...searchParams,
                                            area_id: obj ? obj.value : ''
                                        })} values={searchParams.area_id} />
                                        <InputTextGroup placeholder={t('properties.result.bedrooms')}
                                            type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                                ...searchParams,
                                                bedrooms: e.target.value
                                            })}
                                            value={searchParams.bedrooms}
                                        />
                                        <InputTextGroup placeholder={t('properties.result.bathrooms')}
                                            type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                                ...searchParams,
                                                bathrooms: e.target.value
                                            })}
                                            value={searchParams.bathrooms}
                                        />
                                        <InputTextGroup placeholder={t('properties.result.total_area_min')}
                                            type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                                ...searchParams,
                                                area_min: e.target.value
                                            })}
                                            value={searchParams.area_min}
                                        />
                                        <InputTextGroup placeholder={t('properties.result.total_area_max')}
                                            type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                                ...searchParams,
                                                area_max: e.target.value
                                            })}
                                            value={searchParams.area_max}
                                        />
                                        <InputTextGroup placeholder={t('properties.result.price_min')}
                                            type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                                ...searchParams,
                                                price_from: e.target.value
                                            })}
                                            value={searchParams.price_from}
                                        />
                                        <InputTextGroup placeholder={t('properties.result.price_max')}
                                            type={'number'} inputGroupClass={'mt-2 rounded-3'} onChange={(e) => setSearchParams({
                                                ...searchParams,
                                                price_to: e.target.value
                                            })}
                                            value={searchParams.price_to}
                                        />
                                        <Button onClick={() => searchProperties()} buttonText={t('properties.result.button')} buttonClass={'mt-2 w-100 btn-primary fw-bold'} />
                                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-8 col-xl-9 mt-3 mt-xl-0">
                        <ResultsCard className='mt-3'>
                            {properties.map((property, index) => (
                                <PropertyCard property={property} key={index} />
                            ))}
                        </ResultsCard>
                        {last_page !== 1 && <div className='d-flex align-items-center justify-content-center mt-5'>
                            <Pagination last_page={last_page} page={page} collectData={searchFetchProperties} searchParams={searchParams} />
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResultPage