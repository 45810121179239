import React, { useEffect, useState } from 'react'
import bedroomIcon from '../assets/icons/bedrooms.png'
import bathroomsIcon from '../assets/icons/bathrooms.png'
import totalArea from '../assets/icons/total_area.png'
import interiorArea from '../assets/icons/interior_area.png'
import livingRooms from '../assets/icons/living_room.png'
import locationProperty from '../assets/icons/location_property.png'
import documentsIcon from '../assets/icons/documents.png'
import floorIcon from '../assets/icons/floor.png'
import RelatedPropertiesSlider from '../components/RelatedPropertiesSlider'
import Button from '../components/common/Button'
import PropertyGallery from '../components/PropertyGallery'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchProperty, resetPropertyFetched } from '../feature/properties/propertiesActions'
import { useSelector } from 'react-redux'
import PageLoader from '../components/common/PageLoader'
import { availability, business_type } from '../constants'
import { BACKEND_STORAGE_URL } from '../config/urls'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import axe from '../services/axios-client/axiosClient'
import { generalAlertErrorToast, generalAlertSuccessToast } from '../utils/alert'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import shareImg from '../assets/share-img.webp'


function SinglePropertyShare() {
  const { t } = useTranslation()
  const { propertySlug } = useParams()
  const navigate = useNavigate()
  const { loading, property, related_properties } = useSelector(store => store.properties.property)
  const [sendingMessage, setSendingMessage] = useState(false)
  const schema = yup.object({
    name: yup.string().required(t('work_with_us.form.validation.name')),
    surname: yup.string().required(t('work_with_us.form.validation.surname')),
    phone: yup.string().matches(/^[0-9+]+$/, t('contact.form.validation.phone')).required(t('contact.form.validation.phone')),
    email: yup.string().nullable().email(t('contact.form.validation.email')),
    message: yup.string().required(t('contact.form.validation.message'))
  })

  const { formState: { errors }, handleSubmit, setValue, watch, reset } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      surname: "",
      phone: '',
      email: '',
      message: ''
    }
  })

  const formData = watch()

  const updateFormData = (data) => {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      setValue(key, data[key], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    });
  };

  const sendMessage = async () => {
    try {
      setSendingMessage(true)

      let data = {
        ...formData,
        property_id: property.id
      }

      const response = await axe.post('/contact-agent', data)

      if (response.status !== 200) {
        setSendingMessage(false)
        if (localStorage.getItem('language') === 'sq') {
          generalAlertErrorToast('Dicka shkoi gabim! Mesazhi nuk u dergua!')
        }
        if (localStorage.getItem('language') === 'en') {
          generalAlertErrorToast('Something went wrong! Message was not sent!')
        }
      }

      if (response.status === 200) {
        setSendingMessage(false)
        if (localStorage.getItem('language') === 'sq') {
          generalAlertSuccessToast('Mesazhi u dergua me sukses!')
        }
        if (localStorage.getItem('language') === 'en') {
          generalAlertSuccessToast('Message was succesfully sent!')
        }
        reset()
      }
    } catch (error) {
      setSendingMessage(false)
      if (localStorage.getItem('language') === 'sq') {
        generalAlertErrorToast('Dicka shkoi gabim! Mesazhi nuk u dergua!')
      }
      if (localStorage.getItem('language') === 'en') {
        generalAlertErrorToast('Something went wrong! Message was not sent!')
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    fetchProperty(propertySlug)

    return () => {
      resetPropertyFetched()
    }
  }, [propertySlug, localStorage.getItem('language')])

  if (loading) {
    return <PageLoader />
  }

  return (
    <>
      <Helmet>
        <title data-n-head="true">{property.title_al ? property.title_al : property.title_en} | ProALB Realestate Group</title>
        <meta name="description" content=" ProALB RealEstate Group është angazhuar të jetë një vlerë e shtuar në sektorin e pasurive të paluajtshme duke ofruar eksperienca të shkëlqyera për të gjithë bashkëpunëtorët tanë." />
        <meta name="keywords" content="proalb, realestate, albania, tirana, prona, ne shitje, qera, apartamente, imobiliare, vila, studjo, biznese, trruall, komerciale, shtepi, shpi ne shitje, shpi me qera, rezidenca banimi, komplekse, ndertesa" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta property="og:image" content={`https://proalbrealestate.com/storage/${property.cover_image}`} />
      </Helmet>
      <div className="container-xl my-5">
        <div className="row mb-3">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap">
              <div>
                <h1 className='h3 text-center text-lg-start'>
                  {property.title_al ? property.title_al : property.title_en}
                </h1>
                <p className='mb-0 text-center text-lg-start'>
                  <FontAwesomeIcon icon={faLocationDot} /> {property.property_location.address_al ? property.property_location.address_al : property.property_location.address_en}
                </p>
              </div>
              <div className='d-flex align-items-center align-items-lg-end justify-content-center justify-content-lg-end flex-column'>
                <h2 className='h2 mb-0 text-secondary fw-bolder text-center text-lg-start'>
                  {property.property_price.price_upon_request == 0 ? `€ ${property.property_price.price.toLocaleString()}` : t('property.info.price_upon_request')}
                </h2>
                <p className='mb-0 text-dark text-end text-center text-lg-start'>
                  ID: {property.property_id}
                </p>
                <p className='mb-0 text-dark text-end'>
                  {['reserved', 'in_negotiation'].includes(property.availability) ? availability.find(ty => ty.id == property.availability)[localStorage.getItem('language')] :
                    business_type.find(ty => ty.id == property.property_price.business_type)[localStorage.getItem('language')]}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-stretch mb-2">
          <div className="col-12 col-md-12 col-lg-7">
            <PropertyGallery gallery={property.property_gallery} cover={property.cover_image} />
          </div>
          <div className="col-5 d-none d-lg-block">
            <div className='card card-body h-100 justify-content-start'>
              <div className="row">
                <div className="col-12">
                  <img src={shareImg} alt={property.property_id} className='img-fluid rounded-3' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row align-items-stretch">
          <div className="col-12 col-md-12 col-lg-7">
            <div className='card card-body h-100 justify-content-between'>
              <div className="row my-3">
                <div className="col-6 col-md-4 my-2">
                  <div className='d-flex align-items-center justify-content-start gap-2'>
                    <img src={totalArea} width={35} alt="Total Area" />
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                      <h5 className='h6 mb-0 text-secondary fw-bold'>
                        {t('property.info.total_area')}
                      </h5>
                      <p className='mb-0 fs-6 fw-medium'>{parseFloat(property.property_area.total_m2)}m<sup>2</sup></p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 my-2">
                  <div className='d-flex align-items-center justify-content-start gap-2'>
                    <img src={interiorArea} width={35} alt="Total Area" />
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                      <h5 className='h6 mb-0 text-secondary fw-bold'>
                        {t('property.info.interior_area')}
                      </h5>
                      <p className='mb-0 fs-6 fw-medium'>{parseFloat(property.property_area.interior_m2)}m<sup>2</sup></p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 my-2">
                  <div className='d-flex align-items-center justify-content-start gap-2'>
                    <img src={bedroomIcon} width={35} alt="Total Area" />
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                      <h5 className='h6 mb-0 text-secondary fw-bold'>
                        {t('property.info.bedrooms')}
                      </h5>
                      <p className='mb-0 fs-6 fw-medium'>{property.property_division.bedrooms}</p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 my-2">
                  <div className='d-flex align-items-center justify-content-start gap-2'>
                    <img src={bathroomsIcon} width={35} alt="Total Area" />
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                      <h5 className='h6 mb-0 text-secondary fw-bold'>
                        {t('property.info.bathrooms')}
                      </h5>
                      <p className='mb-0 fs-6 fw-medium'>
                        {property.property_division.bathrooms}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 my-2">
                  <div className='d-flex align-items-center justify-content-start gap-2'>
                    <img src={livingRooms} width={35} alt="Total Area" />
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                      <h5 className='h6 mb-0 text-secondary fw-bold'>
                        {t('property.info.living_rooms')}
                      </h5>
                      <p className='mb-0 fs-6 fw-medium'>
                        {property.property_division.living_rooms}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 my-2">
                  <div className='d-flex align-items-center justify-content-start gap-2'>
                    <img src={floorIcon} width={35} alt="Total Area" />
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                      <h5 className='h6 mb-0 text-secondary fw-bold'>
                        {t('property.info.floor')}
                      </h5>
                      <p className='mb-0 fs-6 fw-medium'>
                        {property.property_location.floor}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 my-2">
                  <div className='d-flex align-items-center justify-content-start gap-2'>
                    <img src={documentsIcon} width={35} alt="Total Area" />
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                      <h5 className='h6 mb-0 text-secondary fw-bold'>
                        {t('property.info.has_documentation')}
                      </h5>
                      <p className='mb-0 fs-6 fw-medium'>
                        {property.property_other.has_documentation == 1 ? t('property.info.has_documentation_yes') : t('property.info.has_documentation_no')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 my-2">
                  <div className='d-flex align-items-center justify-content-start gap-2'>
                    <img src={locationProperty} width={35} alt="Total Area" />
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                      <h5 className='h6 mb-0 text-secondary fw-bold'>
                        {t('property.info.city')}
                      </h5>
                      <p className='mb-0 fs-6 fw-medium'>
                        {property.property_location.city.name}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-6 col-md-4 my-2">
                  <div className='d-flex align-items-center justify-content-start gap-2'>
                    <img src={locationProperty} width={35} alt="Total Area" />
                    <div className='d-flex align-items-start justify-content-between flex-column'>
                      <h5 className='h6 mb-0 text-secondary fw-bold'>
                        {t('property.info.address')}
                      </h5>
                      <p className='mb-0 fs-6 fw-medium'>
                        {property.property_location.address_al ? property.property_location.address_al : property.property_location.address_en}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-5 d-none d-lg-block">
            <div className="card card-body h-100">
              <h4 className="h5 fw-semibold">{t('property.rate.title')}</h4>
              <p className='fw-normal'>{t('property.rate.sub_title')}</p>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="inputEmail4" className="form-label">{t('property.rate.total')}</label>
                  <input type="email" className="form-control rounded-3" id="inputEmail4" />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputEmail4" className="form-label">{t('property.rate.interest')}</label>
                  <input type="email" className="form-control rounded-3" id="inputEmail4" />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputEmail4" className="form-label">{t('property.rate.years')}</label>
                  <input type="email" className="form-control rounded-3" id="inputEmail4" />
                </div>
                <div className="col-md-12 mt-2">
                  <Button buttonClass={'btn-outline-primary w-100'} buttonText={t('property.rate.button')} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="card card-body">
              <h3 className='h5 fw-semibold mb-3 text-dark'>
                {t('property.info.description')}
              </h3>
              <div className="row">
                <div className="col-12 col-md-12 col-lg-8">
                  <p className='text-dark fw-normal' dangerouslySetInnerHTML={{ __html: property.description_al ? property.description_al.replace(/\n/g, '<br>') : property.description_en.replace(/\n/g, '<br>') }} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row d-block d-lg-none mt-2">
          <div className="col-12 d-block d-lg-none">
            <div className='card card-body h-100 justify-content-center'>
              <div className="row">
                <div className="col-12">
                  <img src={shareImg} alt={property.property_id} className='img-fluid rounded-3' />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 d-block d-lg-none mt-2">
            <div className="card card-body h-100">
              <h4 className="h5 fw-semibold">{t('property.rate.title')}</h4>
              <p className='fw-normal'>{t('property.rate.sub_title')}</p>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="inputEmail4" className="form-label">{t('property.rate.total')}</label>
                  <input type="email" className="form-control rounded-3" id="inputEmail4" />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputEmail4" className="form-label">{t('property.rate.interest')}</label>
                  <input type="email" className="form-control rounded-3" id="inputEmail4" />
                </div>
                <div className="col-md-4">
                  <label htmlFor="inputEmail4" className="form-label">{t('property.rate.years')}</label>
                  <input type="email" className="form-control rounded-3" id="inputEmail4" />
                </div>
                <div className="col-md-12 mt-2">
                  <Button buttonClass={'btn-outline-primary w-100'} buttonText={t('property.rate.button')} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <h2 className='h5 text-start text-primary fw-bold mb-4'>
            {t('property.info.related_properties')}
          </h2>
          <RelatedPropertiesSlider paginationClass={'swiper-custom-pagination-related'} related_properties={related_properties} />
        </div>
      </div>
    </>
  )
}

export default SinglePropertyShare