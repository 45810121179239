import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import bathrooms from '../assets/property/bathrooms.svg'
import bedrooms from '../assets/property/bedrooms.svg'
import totalSize from '../assets/property/total_space.svg'
import { PropertyCardStyles } from './PropertyCard.styles'
import { BACKEND_STORAGE_URL } from '../config/urls'
import { availability, business_type } from '../constants'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'



function PropertyCard({ property }) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const goToProperty = () => {
    let path = localStorage.getItem('language') === 'sq' ? `/properties/${property.slug_al}` : `/properties/${property.slug_al}/en`
    console.log(path);
    navigate(path)
  }

  return (
    <PropertyCardStyles className="card" onClick={() => goToProperty()}>
      <div className='position-relative p-2'>
        <span className='badge bg-primary px-3 py-2 position-absolute top-0 start-0 mt-3 ms-3'>
          {['reserved', 'in_negotiation'].includes(property.availability) ? availability.find(ty => ty.id == property.availability)[localStorage.getItem('language')] :
            business_type.find(ty => ty.id == property.property_price.business_type)[localStorage.getItem('language')]}
        </span>
        <img src={BACKEND_STORAGE_URL + property.cover_image} className="img-fluid rounded-3" alt={property.title_al ? property.title_al : property.title_en} />
      </div>
      <div className="card-body pt-1">
        <h5 className="card-title fw-bold fs-5 mb-0"> {property.property_price.price_upon_request == 0 ? `€ ${property.property_price.price.toLocaleString()}` : t('property.info.price_upon_request')}</h5>
        <p className="card-text fw-semibold custom-medium-text mb-0">{localStorage.getItem('language') == 'sq' ? property.title_al : property.title_en}</p>
        <p className="card-text text-muted custom-small-text">
          <FontAwesomeIcon icon={faLocationDot} /> {localStorage.getItem('language') == 'sq' ? property.property_location.address_al : property.property_location.address_en}
        </p>
      </div>
      <div className="card-footer bg-transparent border-0">
        <hr />
        <div className="d-flex align-items-center justify-content-start gap-3">
          <div className='d-flex align-items-center justify-content-between'>
            <img src={totalSize} alt="Size" />
            <p className='ms-2 mb-0 custom-small-text fw-bold text-muted'>{property.property_area.total_m2}m<sup>2</sup></p>
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <img src={bedrooms} alt="Size" />
            <p className='ms-2 mb-0 custom-small-text fw-bold text-muted'>{property.property_division.bedrooms}</p>
          </div>
          <div className='d-flex align-items-center justify-content-between'>
            <img src={bathrooms} alt="Size" />
            <p className='ms-2 mb-0 custom-small-text fw-bold text-muted'>{property.property_division.bathrooms}</p>
          </div>
        </div>
      </div>
    </PropertyCardStyles>
  )
}

export default PropertyCard