import styled from "styled-components";

export const CitiesHomeStyles = styled.div`
  transition: all 250ms linear;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }

  @media (max-width: 768px) {
    max-height: 130px;

    img {
      object-fit: cover;
    }
  }
`;
