import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  agents: {
    loading: true,
    error: null,
    agents: [],
    page: 1,
    last_page: 1,
  },
  agent: {
    loading: true,
    error: null,
    agent: null,
    properties: [],
    page: 1,
    last_page: 1,
  },
};

export const agentsSlice = createSlice({
  name: "agents",
  initialState,
  reducers: {
    fetchAgentsPending: (state) => {
      state.agents.loading = true;
      state.agents.error = null;
    },
    fetchAgentsRejected: (state, { payload }) => {
      state.agents.loading = false;
      state.agents.error = payload;
    },
    fetchAgentsSuccess: (state, { payload }) => {
      state.agents.loading = false;
      state.agents.agents = payload.data;
      state.agents.page = payload.current_page;
      state.agents.last_page = payload.last_page;
    },
    resetFetchAgents: (state) => {
      state.agents = { ...initialState.agents };
    },
    fetchAgentPending: (state) => {
      state.agent.loading = true;
      state.agent.error = null;
    },
    fetchAgentRejected: (state, { payload }) => {
      state.agent.loading = false;
      state.agent.error = payload;
    },
    fetchAgentSuccess: (state, { payload }) => {
      state.agent.loading = false;
      state.agent.agent = payload.agent;
      state.agent.properties = payload.properties.data;
      state.agent.page = payload.properties.current_page;
      state.agent.last_page = payload.properties.last_page;
    },
    resetFetchAgent: (state) => {
      state.agent = { ...initialState.agent };
    },
  },
});
