import styled from "styled-components";

export const PropertyCardStyles = styled.div`
  transition: all 250ms linear;
  cursor: pointer;
  height: 100%;

  &:hover {
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.4);
  }
`;
