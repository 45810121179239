import React, { useState } from 'react'
import Select from 'react-select'

function ReactSelectCustom({ options, error, onChange, values, name, placeholder }) {
    const [inputText, setInputText] = useState('');

    let defaultBorder = '#CBCBCD';


    const handleInputChange = (inputValue) => {
        setInputText(inputValue);
    };

    const filteredItem = options.filter((item) => item.value == values)

    return (
        <Select
            onInputChange={handleInputChange}
            options={options}
            isSearchable
            placeholder={placeholder}

            name={name}
            value={filteredItem}
            onChange={(val) => onChange(val)}
            loadingMessage={() => "Po karikohen te dhenat..."}
            isClearable
            noOptionsMessage={() => "Nuk u gjeten info!"}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    // padding: '6px 10px',
                    // fontWeight: 'bold',
                    borderRadius: '0.5rem',
                    marginTop: '0.5rem',
                    boxShadow: 'none',
                    '&:focus-within': {
                        borderColor: '#888f97;',
                        boxShadow: '0 0 0 0.25rem rgba(16, 31, 46, 0.25)',
                    }
                }),
            }}
        />
    )
}

export default ReactSelectCustom