import styled from "styled-components";

export const AgentCards = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;

  @media screen and (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 451px) and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1025px) and (max-width: 1400px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const AgentCard = styled.div`
  transition: all 250ms linear;
  cursor: pointer;

  img {
    width: -webkit-fill-available;
  }

  &:hover {
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.4);
  }

  p {
    color: #adb5bd;
  }
`;

export const AgentImage = styled.div`
  width: 150px;
  border-radius: 50%;

  img {
    width: 150px;
    height: 150px;
  }
`;

export const SingleAgentImg = styled.div`
  max-width: 100%;

  img {
    width: 100%;
  }

  @media (min-width: 600px) and (max-width: 991px) {
    max-width: 80%;

    img {
      width: 80%;
    }
  }
`;

export const SingleAgentInfo = styled.div``;

export const CardsSection = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @media screen and (max-width: 550px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 551px) and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 901px) and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
