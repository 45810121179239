import React, { useState } from 'react'
import Button from '../../components/common/Button'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { HomeHeaderContainerStyles } from './Home.styles';
import { main_types, property_types } from '../../constants';
import { useNavigate } from 'react-router-dom'

function HomeHeader({ areas }) {
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useState({
    business_type: 2,
    property_type: '',
    city_id: '',
    area_id: '',
    bedrooms: '',
    bathrooms: '',
    price_from: '',
    price_to: '',
    area_min: '',
    area_max: '',
    search: ''
  })

  const handleSearch = (e) => {
    setSearchParams({
      ...searchParams,
      [e.target.name]: e.target.value
    })
  }

  const searchProperties = () => {
    navigate('/properties', {
      state: {
        params: searchParams
      }
    })
  }

  const handleShowMoreFilters = () => {
    setShowMore(!showMore)
  }

  return (
    <HomeHeaderContainerStyles className="container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
          <div className='d-flex align-align-items-center justify-content-center gap-2'>
            <Button buttonClass={`${searchParams.business_type == 2 ? 'btn-white bg-white border-primary' : 'btn-primary'}`} onClick={() => setSearchParams({
              ...searchParams,
              business_type: 2
            })} buttonText={t('home.header.sale')} />
            <Button buttonClass={`${searchParams.business_type == 1 ? 'btn-white bg-white' : 'btn-primary'}`} onClick={() => setSearchParams({
              ...searchParams,
              'business_type': 1
            })} buttonText={t('home.header.rent')} />
          </div>
        </div>
        <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 mt-2">
          <div className='d-flex justify-content-start align-items-center gap-2 flex-wrap flex-lg-nowrap'>
            <Select
              placeholder={t('properties.result.main_type')}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: '8px'
                })
              }}
              onChange={(obj) => setSearchParams({
                ...searchParams,
                main_type: obj.value
              })}
              className='w-100 rounded-3'
              defaultValue={''}
              options={main_types.map(typ => ({ value: typ.id, label: localStorage.getItem("language") === 'sq' ? typ.al : typ.en }))}
            />
            <Select
              placeholder="Zona"
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  borderRadius: '8px'
                })
              }}
              className='w-100 rounded-3'
              defaultValue={''}
              onChange={(obj) => setSearchParams({
                ...searchParams,
                area_id: obj.value
              })}
              options={areas.map(area => ({ value: area.id, label: area.name }))}
            />
            <Button buttonClass={'btn-secondary text-white fw-semibold px-3'} buttonText={t('home.header.search')} onClick={() => searchProperties()} />
          </div>
        </div>
        <div className="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 mt-1">
          <div className='text-white'>
            <small style={{ 'cursor': 'pointer' }} onClick={() => handleShowMoreFilters()}>{t('home.header.more_filters')}</small>
          </div>
          {showMore && <div className="d-flex justify-content-start align-items-center gap-2 flex-wrap flex-lg-nowrap">
            <input type="number" className='w-100 form-control' name="bedrooms" placeholder='Dhoma Gjumi' onChange={(e) => handleSearch(e)} />
            <input type="number" className='w-100 form-control' name="bathrooms" placeholder='Tualete' onChange={(e) => handleSearch(e)} />
            <input type="number" className='w-100 form-control' name='area_min' placeholder='Siperfaqja Min' onChange={(e) => handleSearch(e)} />
            <input type="number" className='w-100 form-control' name='area_max' placeholder='Siperfaqja Max' onChange={(e) => handleSearch(e)} />
            <input type="number" className='w-100 form-control' name='price_from' placeholder='Cmimi Min' onChange={(e) => handleSearch(e)} />
            <input type="number" className='w-100 form-control' name='price_to' placeholder='Cmimi Max' onChange={(e) => handleSearch(e)} />
          </div>}
        </div>
      </div>
    </HomeHeaderContainerStyles>
  )
}

export default HomeHeader