import React from 'react'
import Layout from './Layout'
import Footer from '../components/Footer'
import { Outlet } from 'react-router'
import Navbar from '../components/Navbar'


function Base() {
    return (
        <Layout>
            <Navbar />
            <Outlet />
            <Footer />
        </Layout>
    )
}

export default Base