import styled from "styled-components";

export const NotFoundStyles = styled.div`
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShortDesc = styled.div`
  width: 65%;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: 100%;
  }
`;
