import React, { useEffect, useState } from 'react'
import phone from '../assets/icons/telephone.svg'
import location from '../assets/icons/location.svg'
import email from '../assets/icons/email.svg'
import InputTextGroup from '../components/forms/InputTextGroup'
import InputTextArea from '../components/forms/InputTextArea'
import Button from '../components/common/Button'
import { useTranslation } from 'react-i18next'
import PageLoader from '../components/common/PageLoader'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import axe from '../services/axios-client/axiosClient'
import { generalAlertErrorToast, generalAlertSuccessToast } from '../utils/alert'
import { Helmet } from 'react-helmet'
import locationImg from '../assets/images/location_proalb.webp'

function ContactUs() {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(true)

    const schema = yup.object({
        full_name: yup.string().matches(/^[a-zA-Z\s]+$/, t('contact.form.validation.full_name')).required(t('contact.form.validation.full_name')),
        phone: yup.string().matches(/^[0-9+]+$/, t('contact.form.validation.phone')).required(t('contact.form.validation.phone')),
        email: yup.string().nullable().email(t('contact.form.validation.email')),
        message: yup.string().required(t('contact.form.validation.message'))
    })

    const { formState: { errors }, handleSubmit, setValue, watch, reset } = useForm({
        mode: 'all',
        resolver: yupResolver(schema),
        defaultValues: {
            full_name: "",
            phone: '',
            email: '',
            message: ''
        }
    })

    const updateFormData = (data) => {
        const keys = Object.keys(data);
        keys.forEach((key) => {
            setValue(key, data[key], {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
            });
        });
    };

    const formData = watch()

    const submitData = async () => {
        try {
            setLoading(true)
            const response = await axe.post('/contact', formData)

            if (response.status !== 200) {
                setLoading(false)
                if (localStorage.getItem('language') === 'sq') {
                    generalAlertErrorToast('Dicka shkoi gabim! Mesazhi nuk u dergua!')
                }
                if (localStorage.getItem('language') === 'en') {
                    generalAlertErrorToast('Something went wrong! Message was not sent!')
                }
            }

            if (response.status === 200) {
                setLoading(false)
                if (localStorage.getItem('language') === 'sq') {
                    generalAlertSuccessToast('Mesazhi u dergua me sukses!')
                }
                if (localStorage.getItem('language') === 'en') {
                    generalAlertSuccessToast('Message was succesfully sent!')
                }
            }
            reset()
        } catch (error) {
            setLoading(false)
            if (localStorage.getItem('language') === 'sq') {
                generalAlertErrorToast('Dicka shkoi gabim! Mesazhi nuk u dergua!')
            }
            if (localStorage.getItem('language') === 'en') {
                generalAlertErrorToast('Something went wrong! Message was not sent!')
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 500);
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (loading) {
        return <PageLoader />
    }

    return (
        <>
            {localStorage.getItem('language') === 'sq' ?
                <Helmet>
                    <title data-n-head="true">Na Kontaktoni | ProALB Realestate Group | Qira, Shitje </title>
                    <meta name="description" content="Na kontakti ne numrin e telefonit +355675862122, ne adresen tone te emailit info@proalbrealestate.com . Gjithashtu mund te na vizitoni prane zyrave tone ne Rrugen e Kavajes Rezidenca Garden Building Kati 2" />
                    <meta property="og:url" content="https://proalbrealestate.com/contact " />
                    <meta name="keywords" content="na kontaktoni, kontakt, proalb, realestate, albania, tirana, prona, ne shitje, qera, apartamente, imobiliare, vila, studjo, biznese, trruall, komerciale, shtepi, shpi ne shitje, shpi me qera, rezidenca banimi, komplekse, ndertesa" />
                    <meta property="og:locale" content="sq_AL" />
                    <meta property="og:type" content="article" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta property="og:image" content="https://proalbrealestate.com/images/icon.png" />
                </Helmet> :
                <Helmet>
                    <title data-n-head="true">Contact Us| ProALB Realestate Group | Rent, Sale</title>
                    <meta name="description" content="Contact us at phone number +355675862122, at our email address info@proalbrealestate.com. You can also visit us near our offices on Rrugen e Kavaje, Rezidenca Garden Building, Floor 2" />
                    <meta property="og:url" content="https://proalbrealestate.com/en/contact " />
                    <meta name="keywords" content="contactus, contact, proalb, realestate, albania, tirana, property, for sale, rent, apartments, real estate, villas, studios, businesses, plots, commercial, houses, houses for sale, houses for rent, residential residences, complexes, buildings" />
                    <meta property="og:locale" content="sq_en" />
                    <meta property="og:type" content="article" />
                    <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                    <meta property="og:image" content="https://proalbrealestate.com/images/icon.png" />
                </Helmet>}
            <div className="container-fluid p-0">
                <img src={locationImg} alt="Proalb Real Estate Location" className='w-100' />
            </div>
            <h1 className='text-center fw-bold mt-5'>{t('contact.title')}</h1>
            <div className="container-fluid px-md-5 my-5">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="card py-4 h-100">
                            <div className="card-body d-flex justify-content-center align-items-start mx-auto flex-column">
                                <div className='d-flex align-items-center justify-content-center gap-3'>
                                    <img src={phone} alt="Phone" />
                                    <h5 className='m-0'>+355 67 586 2122</h5>
                                </div>
                                <div className='d-flex align-items-center justify-content-center mt-5 gap-3'>
                                    <img src={location} alt="Address" />
                                    <h5 className='m-0'>Rruga e Kavajes, Tirane, Albania</h5>
                                </div>
                                <div className='d-flex align-items-center justify-content-center mt-5 gap-3'>
                                    <img src={email} alt="Email" />
                                    <h5 className='m-0'>info@proalbrealestate.com</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-3 mt-lg-0">
                        <div className="card py-4 px-2 px-md-5 h-100">
                            <div className="card-body">
                                <form onSubmit={handleSubmit(submitData)}>
                                    <InputTextGroup
                                        error={errors.full_name?.message}
                                        name={'full_name'}
                                        value={formData.full_name}
                                        placeholder={t('contact.form.name')}
                                        onChange={(e) => {
                                            updateFormData({
                                                full_name: e.target.value
                                            })
                                        }}
                                        type={'text'}
                                        inputGroupClass={'mt-2'} />
                                    <InputTextGroup
                                        placeholder={t('contact.form.phone')}
                                        name={'phone'}
                                        value={formData.phone}
                                        error={errors.phone?.message}
                                        onChange={(e) => {
                                            updateFormData({
                                                phone: e.target.value
                                            })
                                        }}
                                        type={'text'}
                                        inputGroupClass={'mt-2 '} />
                                    <InputTextGroup
                                        name={'email'}
                                        value={formData.email}
                                        placeholder={t('contact.form.email')}
                                        error={errors.email?.message}
                                        onChange={(e) => {
                                            updateFormData({
                                                email: e.target.value
                                            })
                                        }}
                                        type={'email'}
                                        inputGroupClass={'mt-2'} />
                                    <InputTextArea
                                        name={'message'}
                                        value={formData.message}
                                        error={errors.message?.message}
                                        onChange={(e) => {
                                            updateFormData({
                                                message: e.target.value
                                            })
                                        }}
                                        placeholder={t('contact.form.message')} textAreaDivClass={'mt-2'} />
                                    <Button type={'submit'} buttonText={t('contact.form.button')} buttonClass={'w-100 mt-2 btn-primary text-white fw-bold'} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs